<template>
  <b-modal
    ref="offer-motive"
    title="Create Motive"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-form-group label="Motive:">
        <validation-provider
          v-slot="{ errors }"
          name="description"
          rules="required"
        >
          <b-form-input
            v-model="descriptionMotive"
            :state="errors[0] ? false : null"
          />
          <span v-if="errors[0]" class="text-danger mt-1">
            Motive {{ errors[0] }}</span
          >
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="saveMotive()" />
    </template>
  </b-modal>
</template>

<script>
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {},
  data() {
    return {
      descriptionMotive: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("offer-motive");
  },
  methods: {
    close() {
      this.$emit("closing");
    },

    async saveMotive() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader();
            const data = await PriorityOffersService.setNewMotive({
              motive: this.descriptionMotive,
              user_id: this.currentUser.user_id,
            });
            if (data.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.close();
              this.$emit("closing", 1);
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
};
</script>
<style scoped></style>
