import { amgApi } from "@/service/axios";

class SettingsService {
  async getSetting(body) {
    const data = await amgApi.post("/settings/save-msg-setting", body);
    return data;
  }

  async getMessageWelcome() {
    const data = await amgApi.get("debt-solution/get-message-welcome");
    return data;
  }

  async getOfferOptions() {
    const data = await amgApi.get("debt-solution/get-offers-option");
    return data;
  }

  async updateMessageWelcome(body) {
    const response = await amgApi.post(
      "debt-solution/update-message-welcome", 
      body
    );
    return response;
  }

  async updateOfferMessage(body) {
    const response = await amgApi.post(
      "debt-solution/update-offer-message",
      body
    );
    return response;
  }

  async sendOffersMessage(body) {
    const response = await amgApi.post(
      "debt-solution/send-offers-message",
      body
    );
    return response;
  }

  async getOfferMotive() {
    try {
      const data = await amgApi.post("/debt-solution/get-offer-motive");
      return data;
    } catch (error) {
      console.log("Error getOfferMotive");
    }
  }

  async getOfferPaymentOrderMotives(motiveType) {
    try {
      const data = await amgApi.post(
        "/debt-solution/get-offer-payment-order-motives",
        {
          motiveType,
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async updateOfferPaymentOrderMotive(body) {
    try {
      const data = await amgApi.post(
        "/debt-solution/update-offer-payment-order-motive",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addOfferPaymentOrderMotive(body) {
    try {
      const data = await amgApi.post(
        "/debt-solution/add-offer-payment-order-motive",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeOfferPaymentOrderMotive(body) {
    try {
      const data = await amgApi.post(
        "/debt-solution/remove-offer-payment-order-motive",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertMessageBinnacle(body) {
    try {
      const data = await amgApi.post("/debt-solution/settlements-made/register-message-binnacle", body);
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  async updateMessageBinnacle(body) {
    try {
      const data = await amgApi.post("/debt-solution/settlements-made/update-message-binnacle", body);
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  async getDefaultSmsFinancialAnalysis() {
    try {
      const data = await amgApi.get("/debt-solution/list-default-sms-financial-analysis");
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  async createOrUpdateDefaultSmsFinancialAnalysis(body) {
    try {
      const data = await amgApi.post("/debt-solution/create-or-update-default-sms-financial-analysis", body);
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  async setDefaultFinancialAnalysisMessages(params){
    try {
      const data = await amgApi.post("/debt-solution/set-default-financial-analysis-messages", params);
      return data
    } catch (error) {
      throw(error)
    }
  }

  async getIsDebtor( params ) {
    try {
      const data = await amgApi.post("/debt-solution/get-is-debtor", params);
      return data
    } catch (error) {
      throw(error)
    }
  }

  async getTotalBalance( params ) {
    try {
      const data = await amgApi.post("/debt-solution/get-total-balance", params);
      return data
    } catch (error) {
      throw(error)
    }
  }
}

export default new SettingsService();
