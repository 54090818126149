<template>
  <b-modal
    ref="trackingBalance"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="titleModal"
    size="xlg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template #modal-title>
      <tabler-icon icon="BuildingBankIcon" class="text-white" size="22" />
      <span v-html="titleModal"></span>
    </template>
    <b-table-simple
      sticky-header="50vh"
      class="position-relative table-new-customization"
    >
      <b-thead class="text-center">
        <b-tr>
          <b-th>Old Balance</b-th>
          <b-th>Operation Amount</b-th>
          <b-th>Balance</b-th>
          <b-th>Operation Action</b-th>
          <b-th>Created At</b-th>
        </b-tr>
      </b-thead>
      <b-tbody class="text-center">
        <b-tr v-for="(item, index) in itemTracking" :key="index">
          <b-td>
            <template v-if="!item.initial">
              {{ item.old_balance | formatMoney }}
            </template>
            <template v-else> - </template>
          </b-td>
          <b-td>
            <template v-if="!item.initial">
              {{ item.balance | formatMoney }}
            </template>
            <template v-else> - </template>
          </b-td>
          <b-td>
            <strong>
              {{ (newBalance(item) || 0) | formatMoney }}
            </strong>
          </b-td>
          <b-td>
            <b-badge :variant="getVariant(item)">
              <feather-icon :icon="item.icon"></feather-icon>
              {{ item.type }}
            </b-badge>
          </b-td>
          <b-td>{{ item.created_at | myGlobalDay }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-modal>
</template>
<script>
export default {
  name: "TrackingECheckBalance",
  props: {
    trackingBalance: {
      type: Array,
      required: true,
    },
    bankAccount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    itemTracking() {
      return this.trackingBalance.sort(
        (a, b) => Date(a.created_at) - Date(b.created_at)
      );
    },
    titleModal() {
      const { bank_name, account_name, account_number } = this.bankAccount;
      if (bank_name && account_name && account_number) {
        return `
        <strong>Bank:</strong> ${bank_name} | 
        <strong>Account Name:</strong> ${account_name} | 
        <strong>Account Number:</strong> ${account_number}`;
      } else {
        return `Tracking Balance`;
      }
    },
  },
  mounted() {
    this.toggleModal("trackingBalance");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    newBalance(item) {
      if (item.type === "DEPOSIT") {
        return Number(item.balance) + Number(item.old_balance);
      } else if (item.type === "WITHDRAWAL") {
        return Number(item.old_balance) - Number(item.balance);
      } else {
        return item.balance;
      }
    },
    getVariant(item) {
      if (item.type === "DEPOSIT") {
        return "light-success";
      } else if (item.type === "WITHDRAWAL") {
        return "light-warning";
      } else {
        return "light-primary";
      }
    },
  },
};
</script>