<template>
  <b-modal
    v-model="isActive"
    title="TRACKING"
    modal-class="modal-primary"
    size="lg"
    hide-footer
    @hidden="handleHidden"
  >
    <b-table
      ref="trackingList"
      sticky-header="50vh"
      small
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      primary-key="id"
      responsive="sm"
      :items="tableProvider"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(process)="data">
        <div
          v-if="data.item.type_tracking === 1"
          class="d-flex text-center justify-content-start"
        >
          <b-badge :variant="getProcess(data.item, 'color')" pill>{{
            getProcess(data.item, "data")
          }}</b-badge>
        </div>
        <div v-else class="d-flex justify-content-start">
          <b-badge
            class="text-left"
            :variant="getProcess(data.item, 'color')"
            pill
            >{{ getProcess(data.item, "data") }}</b-badge
          >
        </div>
      </template>
      <template #cell(date)="data">
        <span> {{ data.value | myGlobalDay }} </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    show: Boolean,
    offer: Number,
  },
  data() {
    return {
      isActive: false,
      fields: [
        {
          key: "user",
          label: "User",
        },
        {
          key: "process",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "comment",
          label: "comment",
        },
      ],
    };
  },
  created() {
    this.onControl = true;
  },
  methods: {
    getProcess(item, type = "color") {
      if (item.type_tracking === 1) {
        switch (item.status) {
          case "PENDING":
            if (item.process === "VERIFICATION" && item.comment === "PENDING") {
              return type === "data" ? "CREATE SETTLEMENT" : "light-secondary";
            } else if (
              item.process === "VERIFICATION" &&
              item.comment != "PENDING"
            ) {
              return type == "data" ? "ACCEPT SETTLEMENT" : "light-warning";
            } else if (item.process === "UNDERVIEW") {
              return type == "data" ? "AUTHORIZE SETTLEMENT" : "light-primary";
            }
            break;
          case "REJECTED":
            console.log(item.process);
            return type == "data" ? "REJECTED SETTLEMENT" : "light-danger";
          case "APPROVED":
            return type == "data" ? "APPROVE SETTLEMENT" : "light-success";
        }
      } else {
        switch (item.status_num) {
          case 1:
            return type == "data" ? "GENERATE CHECK" : "light-secondary";
          case 2:
            return type == "data" ? item.status : "light-warning";
          case 3:
            return type == "data" ? item.status : "light-danger";
          case 4:
            return type == "data" ? item.status : "light-primary";
          case 5:
            return type == "data" ? item.status : "light-info";
          case 6:
            return type == "data" ? item.status : "light-success";
          case 7:
            return type == "data" ? item.status : "light-primary";
          case 8:
            return type == "data" ? item.status : "light-info";
          default:
            break;
        }
      }
      // return item.process;
    },
    handleHidden() {
      this.isActive = false;
      this.$emit("closing");
    },
    async tableProvider() {
      let res = await amgApi.post("/debt-solution/get-offer-tracking", {
        offer_id: this.offer,
      });

      return res.data;
    },
    colorizeProcess(process) {
      // REQUEST, VERIFICATION, UNDERVIEW
      switch (process) {
        case 3:
          return "light-primary";
        case 1:
          return "light-info";
        case 2:
          return "light-warning";
        default:
          return "light-secondary";
      }
    },
    colorizeStatus(status) {
      switch (status) {
        case "REJECTED":
          return "light-danger";
        case "APPROVED":
          return "light-success";
        case "PENDING":
          return "light-info";
        default:
          return "light-secondary";
      }
    },
    getStatusDescription(status, typeTracking) {
      // typeTracking 1: tracking offer, 2: tracking payment order
      switch (typeTracking) {
        case 1:
          switch (status) {
            case 1:
              return "PENDING";
            case 2:
              return "REJECTED";
            case 3:
              return "APPROVED";
            default:
              return "UNKNOWN";
          }
        case 2:
          switch (status) {
            case 1: // PENDING DEBIT CONFIRMATION
              return "Pending Debit Confirmation";
            case 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
              return "Pending Processing Due To Insufficient Funds";
            case 3:
              return "Negotiation not completed"; // Negotiation not completed
            case 4: // Negotiation completed
              return "Negotiation completed";
            case 5:
              return "Pending Completion"; // PENDING COMPLETION
            case 6:
              return "Completed"; // COMPLETED
            default:
              break;
          }
      }
    },
    getStatusIcon(status, typeTracking) {
      // typeTracking 1: tracking offer, 2: tracking payment order
      switch (typeTracking) {
        case 1:
          switch (status) {
            case 1:
              return "ClockIcon";
            case 2:
              return "XCircleIcon";
            case 3:
              return "CheckCircleIcon";
            default:
              return "ClockIcon";
          }
        case 2:
          switch (status) {
            case 1:
              return "CashIcon";
            case 2:
              return "CashIcon";
            case 3:
              return "CashIcon";
            case 4:
              return "CashIcon";
            case 5:
              return "CheckIcon";
            case 6:
              return "ChecksIcon";
            default:
              return "CashIcon";
          }
      }
    },
    getClassStatus(status, typeTracking) {
      // typeTracking 1: tracking offer, 2: tracking payment order
      switch (typeTracking) {
        case 1:
          switch (status) {
            case 1:
              return "text-info";
            case 2:
              return "text-danger";
            case 3:
              return "text-success";
            default:
              return "";
          }
        case 2:
          switch (status) {
            case 1:
            case 5:
              return "text-yellow";
            case 2:
              return "text-warning";
            case 3:
              return "text-danger";
            case 4:
            case 6:
              return "text-success";
            default:
              return "";
          }
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
        // this.$refs["trackingList"].refresh()
      }
    },
  },
};
</script>
<style scoped>
.text-yellow {
  color: #ffd833 !important;
}
</style>