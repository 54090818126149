<template>
  <b-modal
    ref="settlement-offer-modal"
    :size="currentBreakPoint === 'xxl' ? 'xls' : 'caro'"
    :title="modalTitle"
    hide-footer
    title-class="h3 text-white font-weight-bolder"
    no-close-on-backdrop
    scrollable
    @hidden="handleHidden"
  >
    <b-overlay variant="transparent" :show="loading">
      <div>
        <verify-status-request
          ref="verifyStatusRequest"
          :type-permission-id="4"
          :id-table="offerInfo.creditorId"
          @state="wasApprovedCheif = $event"
          in-row
          send-new
          :key="verifyStatusKey"
        ></verify-status-request>
      </div>
      <ValidationObserver ref="cform">
        <b-row class="mx-1">
          <b-col>
            <b-form-group size="lg" label="CLIENT">
              <b-input-group disabled>
                <div class="form-control" style="background: transparent">
                  <router-link
                    :class="[textLink]"
                    :to="{
                      name: 'debt-solution-dashboard',
                      params: {
                        idClient:
                          offerInfo.client_account_id ||
                          offerInfo.clientAccountId,
                      },
                    }"
                    target="_blank"
                  >
                    <div
                      class="d-flex align-items-center"
                      style="margin: 2px 0 0 0"
                    >
                      <span>
                        {{ offerInfo.client }} ({{ offerInfo.account_name }})
                      </span>
                    </div>
                  </router-link>
                </div>
                <b-input-group-append>
                  <b-button v-b-toggle.info-client variant="info" size="sm">
                    <feather-icon icon="ChevronDownIcon" size="16" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group size="lg" label="CREDITOR">
              <b-form-input v-model="offerInfo.creditorName" disabled />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group size="lg" label="AVERAGE % SETTLEMENT">
              <b-form-input v-model="minPercentage" disabled />
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <b-button
              class="mt-1 w-100"
              :variant="getIsDebtor ? 'outline-danger' : 'outline-success'"
              @click="openPaymentModal"
              title="See essential information"
            >
              <feather-icon
                class="cursor-pointer my-auto"
                icon="DollarSignIcon"
                size="16"
              />
              <span style="margin-left: 8px"> PAYMENTS</span>
            </b-button>
          </b-col>
          <b-col
            lg="2"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <b-button
              class="mt-1 w-100"
              variant="outline-primary"
              @click="essentialModalOpen"
              :disabled="offerInfo.id_analysis === null"
              title="See essential information"
            >
              <feather-icon
                class="cursor-pointer my-auto"
                icon="EyeIcon"
                size="16"
              />
              <span style="margin-left: 8px">QUESTIONNAIRE</span>
            </b-button>
          </b-col>
        </b-row>

        <b-collapse id="info-client">
          <b-row class="mx-1">
            <b-col class="mb-1">
              <b-form-group
                label="DOB"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <kendo-datepicker
                  :format="'MM/dd/yyyy'"
                  style="width: 100%"
                  v-mask="'##/##/####'"
                  v-model="offerInfo.dob"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col class="mb-1">
              <b-form-group
                label="Address"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <b-form-input
                  v-model="offerInfo.address"
                  :disabled="true"
                  :class="isDarkSkin ? 'text-light' : 'text-dark'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" v-if="offerInfo.ssn">
              <b-form-group
                label="SSN"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <b-form-input
                  v-model="offerInfo.ssn"
                  :disabled="true"
                  :class="isDarkSkin ? 'text-light' : 'text-dark'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" v-if="offerInfo.itin">
              <b-form-group
                label="ITIN"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <b-form-input
                  v-model="offerInfo.itin"
                  :disabled="true"
                  :class="isDarkSkin ? 'text-light' : 'text-dark'"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" v-if="offerInfo.cpn">
              <b-form-group
                label="CPN"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <b-form-input
                  v-model="offerInfo.cpn"
                  :disabled="true"
                  :class="isDarkSkin ? 'text-light' : 'text-dark'"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>

        <b-row class="mt-1">
          <b-col lg="12" md="12">
            <b-row class="content-form mx-1">
              <b-col
                cols="12"
                class="font-weight-bolder"
                style="font-size: 14px; margin-bottom: 5px"
              >
                Settlement
                <hr class="mt-0" />
              </b-col>
              <b-col lg="8" md="12">
                <b-row>
                  <b-col lg="6" md="12" class="mb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="radio2"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text
                            class="prepend__width"
                            style="font-weight: 600"
                            >OFFER RESULT</b-input-group-text
                          >
                        </template>
                        <div
                          class="radio__group"
                          :class="{
                            'radio__group--error':
                              form._check && form.result == null,
                          }"
                        >
                          <b-button-group class="w-100">
                            <b-button
                              v-model="form.result"
                              :variant="
                                errors.length > 0
                                  ? 'outline-danger'
                                  : form.result == 'accept'
                                  ? 'primary'
                                  : 'outline-primary'
                              "
                              :disabled="allDisabled || offerInfo.migrate"
                              @click="form.result = 'accept'"
                              >ACCEPT</b-button
                            >
                            <b-button
                              v-model="form.result"
                              :variant="
                                errors.length > 0
                                  ? 'outline-danger'
                                  : form.result == 'pending'
                                  ? 'primary'
                                  : 'outline-primary'
                              "
                              :disabled="allDisabled || offerInfo.migrate"
                              @click="form.result = 'pending'"
                              >PENDING</b-button
                            >
                            <b-button
                              v-model="form.result"
                              :variant="
                                errors.length > 0
                                  ? 'outline-danger'
                                  : form.result == 'reject'
                                  ? 'primary'
                                  : 'outline-primary'
                              "
                              :disabled="allDisabled || offerInfo.migrate"
                              @click="form.result = 'reject'"
                              >REJECT</b-button
                            >
                          </b-button-group>
                        </div>
                      </b-input-group>
                    </validation-provider>
                  </b-col>
                  <b-col lg="6"></b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" md="8">
                    <b-row>
                      <b-col lg="12" md="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|validate-amount"
                          name="moneyD"
                        >
                          <b-input-group class="mb-1">
                            <template #prepend>
                              <b-input-group-text class="prepend__width">
                                <strong>BALANCE</strong>
                              </b-input-group-text>
                            </template>
                            <vue-money
                              v-model="offer.balance"
                              class="form-control"
                              v-bind="money"
                              :disabled="allDisabled"
                              :class="{ 'border-danger': errors[0] }"
                            />
                          </b-input-group>
                        </validation-provider>
                      </b-col>
                      <b-col lg="9" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="
                            form.result == 'accept'
                              ? 'required|validate-amount'
                              : ''
                          "
                          name="moneyC"
                        >
                          <b-input-group class="mb-1">
                            <template #prepend>
                              <b-input-group-text class="prepend__width">
                                <strong>SETTLEMENT</strong>
                              </b-input-group-text>
                            </template>
                            <vue-money
                              v-model="offer.amount"
                              class="form-control"
                              v-bind="money"
                              :disabled="allDisabled"
                              :class="{
                                'border-danger': errors[0] && form._check,
                              }"
                            />
                          </b-input-group>
                        </validation-provider>
                      </b-col>
                      <b-col lg="3" md="6">
                        <b-input-group class="mb-1">
                          <b-form-input :value="_percentage" disabled />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>SAVING</strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            v-if="offer.amount"
                            :value="_saving"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />
                          <b-form-input
                            v-else
                            disabled
                            value="Waiting for settlement..."
                          />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>
                                SETTLEMENT FEE
                                {{
                                  offerInfo.mode == "create" ||
                                  offerInfo.mode == "pending"
                                    ? ""
                                    : ""
                                }}
                              </strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            v-if="offer.amount"
                            :value="_profit"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />
                          <b-form-input
                            v-else
                            disabled
                            value="Waiting for settlement..."
                          />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12" v-if="isSupervisor || isCeo">
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="
                            form.result == 'accept'
                              ? 'required|validate-amount'
                              : ''
                          "
                          name="moneyB"
                        >
                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text class="prepend__width">
                                <strong> SETTLEMENT COST </strong>
                              </b-input-group-text>
                            </template>
                            <vue-money
                              v-model="form.sCost"
                              class="form-control"
                              v-bind="money"
                              :disabled="
                                !(
                                  ((cStep == 1 ||
                                    offerInfo.mode == 'create' ||
                                    offerInfo.mode == 'pending') &&
                                    isSupervisor) ||
                                  isCeo ||
                                  isAdvisor
                                )
                              "
                              :class="{
                                'border-danger': errors[0] && form._check,
                              }"
                            />
                            <b-input-group-append
                              is-text
                              class="settlement-cost-append"
                            >
                              <b-form-checkbox
                                switch
                                class="mr-n2"
                                v-model="requiredScost"
                                :disabled="
                                  !(
                                    ((cStep == 1 ||
                                      offerInfo.mode == 'create' ||
                                      offerInfo.mode == 'pending') &&
                                      isSupervisor) ||
                                    isCeo ||
                                    isAdvisor
                                  )
                                "
                                >Required</b-form-checkbox
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" md="12">
                    <b-row>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>SETTLEMENT TOTAL</strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            v-if="offer.amount"
                            :value="_settlement_total"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />
                          <b-form-input
                            v-else
                            disabled
                            value="Waiting for settlement..."
                          />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>AMOUNT NEEDED</strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            v-if="offer.amount"
                            :value="_amount_needed"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />
                          <b-form-input
                            v-else
                            disabled
                            value="Waiting for settlement..."
                          />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>CLIENT BALANCE</strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            :value="getTotalBalance"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />
                        </b-input-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-input-group class="mb-1">
                          <template #prepend>
                            <b-input-group-text class="prepend__width">
                              <strong>NEW BALANCE</strong>
                            </b-input-group-text>
                          </template>
                          <vue-money
                            v-if="offer.amount"
                            :value="getNewBalance"
                            class="form-control"
                            v-bind="money"
                            disabled
                          />

                          <b-form-input
                            v-else
                            disabled
                            value="Waiting for settlement..."
                          />
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="d-flex justify-content-center" lg="4" md="12">
                <VueApexCharts
                  class="d-flex justify-content-center align-items-center mt-2"
                  v-if="$options.components.VueApexCharts"
                  height="300"
                  width="340"
                  type="pie"
                  :key="chartId"
                  :options="chartOptions"
                  :series="_series"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="mt-1">
          <b-row class="content-form mx-1">
            <b-col
              cols="12"
              class="font-weight-bolder"
              style="font-size: 14px; margin-bottom: 5px"
            >
              Contact
              <hr class="mt-0" />
            </b-col>
            <b-col cols="4" class="mb-1">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="select1"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >CREDITOR</b-input-group-text
                    >
                  </template>
                  <v-select
                    v-if="
                      offerInfo.mode == 'create' || offerInfo.mode == 'pending'
                    "
                    class="select__creditor"
                    :class="{
                      'select__creditor--error':
                        errors[0] || (form._check && form.creditorId == null),
                    }"
                    label="value"
                    placeholder="Search..."
                    :options="creditorOpts"
                    :value="form.creditorName"
                    :clearable="false"
                    @search="searchCreditors"
                    @input="selectCreditor"
                  />
                  <b-form-input v-else :value="form.creditorName" disabled />
                </b-input-group>
              </validation-provider>
            </b-col>
            <b-col cols="4" class="mb-1">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >CONTACT NUMBER</b-input-group-text
                    >
                  </template>
                  <v-select
                    v-model="creditorNumbers.value"
                    class="select__creditor"
                    label="phone"
                    :clearable="false"
                    placeholder="Select a number..."
                    :options="creditorNumbers.options"
                    :reduce="(option) => option.phone_id"
                    :disabled="allDisabled"
                    :class="{
                      'select__creditor--error':
                        errors[0] || (form._check && form.contact == null),
                    }"
                  />
                  <feather-icon
                    v-if="
                      offerInfo.mode == 'create' || offerInfo.mode == 'pending'
                    "
                    class="text-primary cursor-pointer my-auto"
                    icon="PlusIcon"
                    size="22"
                    @click="statusPhone = true"
                  />
                </b-input-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="4" class="mb-1">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >AGENT</b-input-group-text
                    >
                  </template>
                  <v-select
                    v-model="creditorAgents.value"
                    class="select__creditor"
                    label="agent"
                    :clearable="false"
                    placeholder="Select agent..."
                    :options="creditorAgents.options"
                    :reduce="(option) => option.agent_id"
                    :disabled="allDisabled"
                    :class="{
                      'select__creditor--error':
                        errors[0] || (form._check && form.agent == null),
                    }"
                    style="max-width: 100%"
                  >
                    <template #selected-option="{ agent }">
                      <span class="v-agent">{{ agent }}</span>
                    </template>
                  </v-select>
                  <feather-icon
                    v-if="
                      offerInfo.mode == 'create' || offerInfo.mode == 'pending'
                    "
                    class="text-primary cursor-pointer my-auto"
                    icon="PlusIcon"
                    size="22"
                    @click="statusAgent = true"
                  />
                </b-input-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="4" class="mb-1">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >DATE</b-input-group-text
                    >
                  </template>
                  <div style="flex: 1">
                    <kendo-datepicker
                      :format="'MM/dd/yyyy'"
                      v-mask="'##/##/####'"
                      style="width: 100%"
                      v-model="form.date"
                      :state="errors.length > 0 ? false : null"
                      :disabled="allDisabled"
                    />
                  </div>
                </b-input-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="4" class="mb-1">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="select1"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >ACCOUNT NUMBER</b-input-group-text
                    >
                  </template>
                  <b-form-input
                    v-model="form.account_number"
                    :disabled="allDisabled"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </b-form-input>
                </b-input-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row class="content-form mx-1">
            <b-col
              cols="12"
              class="font-weight-bolder"
              style="font-size: 14px; margin-bottom: 5px"
            >
              Payment
              <hr class="mt-0" />
            </b-col>

            <b-col :cols="form.result == 'reject' ? '3' : '4'" class="mb-1">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="radio1"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >PAYMENT TYPE</b-input-group-text
                    >
                  </template>
                  <div
                    class="radio__group"
                    :class="{
                      'radio__group--error': form._check && form.type == null,
                    }"
                  >
                    <b-form-radio
                      v-model="form.type"
                      :value="1"
                      :state="errors[0] ? false : null"
                      :disabled="allDisabled"
                    >
                      <span style="margin-left: 0.5rem">AMG</span>
                    </b-form-radio>
                    <b-form-radio
                      v-model="form.type"
                      :value="2"
                      :state="errors[0] ? false : null"
                      :disabled="allDisabled"
                    >
                      <span style="margin-left: 0.5rem">CLIENT</span>
                    </b-form-radio>
                  </div>
                </b-input-group>
              </validation-provider>
            </b-col>

            <b-col v-if="form.result == 'accept'" md="12" lg="4" class="mb-1">
              <validation-provider
                name="called_from"
                v-slot="{ errors }"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width">
                      CALLED FROM
                    </b-input-group-text>
                  </template>
                  <v-select
                    v-model="selectCredentialsRingCentral.value"
                    label="credentials_number"
                    :clearable="true"
                    class="select__creditor"
                    placeholder="Select a number..."
                    :options="selectCredentialsRingCentral.options"
                    :reduce="(option) => option.credentials_id"
                    :disabled="
                      allDisabled && $moment(form.date) > $moment('2023-08-26')
                    "
                    @input="selectCredentialsRingCentralChange"
                    :class="{
                      'select__creditor--error': errors[0],
                    }"
                  >
                  </v-select>
                </b-input-group>
              </validation-provider>
            </b-col>

            <b-col v-if="form.result == 'accept'" cols="2" class="mb-1">
              <b-alert
                style="padding: 0.71rem 1rem"
                class="m-0"
                :style="
                  isDarkSkin
                    ? 'color:#fff !important'
                    : 'color:#000 !important; background-color:#d1ecf1 !important;'
                "
                show
                variant="info"
              >
                Total Calls by Number:
                <span>{{ totalCallsByNumberByCreditor }}</span>
              </b-alert>
            </b-col>

            <b-col
              v-if="form.result == 'accept'"
              sm="6"
              md="6"
              lg="2"
              class="mb-1"
            >
              <b-alert
                style="padding: 0.71rem 1rem"
                class="m-0"
                :style="
                  isDarkSkin
                    ? 'color:#fff !important'
                    : 'color:#000 !important; background-color:#d1ecf1 !important;'
                "
                show
                variant="info"
              >
                Total Calls: <span>{{ totalCallsByCreditor }}</span>
              </b-alert>
            </b-col>

            <b-col
              v-if="
                (offerInfo.mode == 'watch' && form.motive_id > 0) ||
                ((offerInfo.mode == 'create' || offerInfo.mode == 'pending') &&
                  !accountBalanceIsMajorThanOfferAndProfit &&
                  (form.result == 'pending' || form.result == 'accept'))
              "
              lg="12"
              md="6"
              class="mb-1"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div :style="form.motive_id ? 'width: 100%;' : 'width: 94%;'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="form.result == 'accept' ? 'required' : ''"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width"
                          >MOTIVE</b-input-group-text
                        >
                      </template>
                      <v-select
                        v-model="selectDate.value"
                        class="select__creditor"
                        label="motive"
                        placeholder="Select a motive..."
                        :options="selectDate.options"
                        :reduce="(option) => option.motive_id"
                        :class="errors[0] ? 'select__creditor--error' : ''"
                        :disabled="allDisabled"
                        @input="selectDateChange"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </div>

                <feather-icon
                  v-if="!form.motive_id"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="22"
                  @click="statusMotive = true"
                />
              </div>
            </b-col>

            <b-col
              v-if="statusCommentary || selectDate.value === 0"
              lg="12"
              md="6"
              class="mb-1"
            >
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-input-group class="w-100">
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >COMMENTARY</b-input-group-text
                    >
                  </template>
                  <b-form-input
                    v-model="commentary"
                    :state="errors.length > 0 ? false : null"
                    :disabled="allDisabled"
                  />
                </b-input-group>
              </ValidationProvider>
            </b-col>

            <template
              v-if="form.result == 'accept' || form.result == 'pending'"
            >
              <template
                v-if="offerInfo.mode == 'watch' || offerInfo.mode == 'pending'"
              >
                <b-col cols="6" class="mb-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="
                      form.result == 'accept' && cAudio == '' ? 'required|' : ''
                    "
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width"
                          >AUDIO FILE</b-input-group-text
                        >
                      </template>
                      <div v-if="cAudio != ''" class="radio__group">
                        <audio
                          class="block col"
                          style="height: 1.5rem"
                          :src="cAudio"
                          controls
                        />
                      </div>
                      <b-form-file
                        v-else
                        v-model="form.audio"
                        :class="{
                          'radio__group--error': errors.length > 0,
                        }"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="6" class="mb-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="
                      form.result == 'accept' && cSl == '' ? 'required|' : ''
                    "
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width"
                          >SL FILE</b-input-group-text
                        >
                      </template>
                      <div class="radio__group">
                        <span
                          v-if="cSl == '' && offerInfo.mode == 'watch'"
                          class="block col"
                          >Was migration</span
                        >
                        <b-form-file
                          v-if="cSl == '' && offerInfo.mode == 'pending'"
                          v-model="form.sl"
                          :class="{
                            'radio__group--error': errors.length > 0,
                          }"
                        />

                        <a
                          v-if="cSl != ''"
                          class="block col"
                          target="_blank"
                          :href="cSl"
                          >View File</a
                        >
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </template>
              <template v-else>
                <template v-if="offerInfo.migrate">
                  <b-col cols="6" class="mb-1">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width"
                          >AUDIO FILE</b-input-group-text
                        >
                      </template>
                      <b-form-file v-model="form.audio" />
                    </b-input-group>
                  </b-col>
                  <b-col cols="6" class="mb-1">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width"
                          >SL FILE</b-input-group-text
                        >
                      </template>
                      <b-form-file v-model="form.sl" />
                    </b-input-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col :cols="isSupervisor || isCeo ? 4 : 6" class="mb-1">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="form.result == 'accept' ? 'required' : ''"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text class="prepend__width"
                            >AUDIO FILE</b-input-group-text
                          >
                        </template>
                        <b-form-file
                          v-model="form.audio"
                          :class="{
                            'radio__group--error': errors.length > 0,
                          }"
                        />
                      </b-input-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col :cols="isSupervisor || isCeo ? 4 : 6" class="mb-1">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="form.result == 'accept' ? 'required|' : ''"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text class="prepend__width"
                            >SL FILE</b-input-group-text
                          >
                        </template>
                        <b-form-file
                          v-model="form.sl"
                          :class="{
                            'radio__group--error': errors.length > 0,
                          }"
                        />
                      </b-input-group>
                    </ValidationProvider>
                  </b-col>
                </template>
              </template>

              <b-col v-if="createsMomentIsBefore" class="mb-1">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="form.result == 'accept' ? 'required' : ''"
                  name="formRadio2"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="prepend__width"
                        >PAYMENT FORMAT</b-input-group-text
                      >
                    </template>
                    <div :class="radioGroupColorBorder" class="radio__group">
                      <b-form-radio
                        value="1"
                        v-model="form.paymentFormat"
                        :state="errors[0] ? false : null"
                        :disabled="allDisabled"
                        @change="form.paymentType = null"
                      >
                        <span class="mr-05">BY PHONE</span>
                      </b-form-radio>
                      <b-form-radio
                        value="2"
                        v-model="form.paymentFormat"
                        :state="errors[0] ? false : null"
                        :disabled="allDisabled"
                        @change="form.paymentType = null"
                      >
                        <span class="mr-05">MAIL</span>
                      </b-form-radio>
                      <b-form-radio
                        value="3"
                        v-model="form.paymentFormat"
                        :state="errors[0] ? false : null"
                        :disabled="allDisabled"
                        @change="form.paymentType = null"
                      >
                        <span class="mr-05">ONLINE</span>
                      </b-form-radio>
                    </div>
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col cols="4" v-if="form.paymentFormat == '2'">
                <b-input-group>
                  <b-input-group-text class="w-100">
                    <div class="w-100 d-flex justify-content-between">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="
                          form.result == 'accept'
                            ? isByMail
                              ? 'truthy||required'
                              : ''
                            : ''
                        "
                      >
                        <b-form-checkbox
                          name="checkbox-state"
                          v-model="creditorAddressComplete"
                          :class="errors[0] ? 'border-checkbox-error' : ''"
                          disabled
                        />
                      </validation-provider>
                      <div class="mr-1">
                        {{
                          creditorAddressComplete
                            ? "CREDITOR ADDRESS COMPLETE"
                            : "CREDITOR ADDRESS INCOMPLETE"
                        }}
                      </div>
                      <feather-icon
                        :icon="allDisabled ? 'EyeIcon' : 'EditIcon'"
                        size="16"
                        class="text-warning cursor-pointer"
                        v-b-tooltip.top="
                          allDisabled ? 'View Creditor' : 'Edit Creditor'
                        "
                        @click="openModalEditCreditor"
                      />
                    </div>
                  </b-input-group-text>
                </b-input-group>
              </b-col>
              <template v-if="createsMomentIsBefore">
                <b-col class="mb-1" cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="form.result == 'accept' ? 'required' : ''"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width">
                          PAYMENT METHOD
                        </b-input-group-text>
                      </template>
                      <div class="radio__group">
                        <b-form-radio
                          class="mr-05"
                          value="1"
                          v-model="form.paymentType"
                          :state="errors[0] ? false : null"
                          :disabled="
                            !aPaymentFormatHasBeenSelected ||
                            allDisabled ||
                            isByMail
                          "
                        >
                          <span class="mr-05">E-CHECK (CHECKING ACC.)</span>
                        </b-form-radio>
                        <b-form-radio
                          class="mr-05"
                          value="2"
                          v-model="form.paymentType"
                          :state="errors[0] ? false : null"
                          :disabled="
                            !aPaymentFormatHasBeenSelected ||
                            allDisabled ||
                            isByPhoneOrOnline
                          "
                        >
                          <span class="mr-05">CHECK(OVERNIGHT)</span>
                        </b-form-radio>
                        <b-form-radio
                          class="mr-05"
                          value="3"
                          v-model="form.paymentType"
                          :state="errors[0] ? false : null"
                          :disabled="
                            !aPaymentFormatHasBeenSelected ||
                            allDisabled ||
                            isByPhoneOrOnline
                          "
                        >
                          <span class="mr-05">CASHIER CHECK</span>
                        </b-form-radio>
                        <b-form-radio
                          class="mr-05"
                          value="4"
                          v-model="form.paymentType"
                          :state="errors[0] ? false : null"
                          :disabled="
                            !aPaymentFormatHasBeenSelected ||
                            allDisabled ||
                            isByPhoneOrOnline
                          "
                        >
                          <span class="mr-05">MONEY ORDER</span>
                        </b-form-radio>
                      </div>
                    </b-input-group>
                  </validation-provider>
                </b-col>
              </template>
              <template v-if="createsMomentIsBefore">
                <b-col class="mb-1" cols="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="form.result == 'accept' ? 'required' : ''"
                    name="formRadio3"
                  >
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="prepend__width">
                          PAYMENT MODE
                        </b-input-group-text>
                      </template>
                      <div class="radio__group">
                        <b-form-radio
                          class="mr-05"
                          value="1"
                          v-model="form.paymentMethod"
                          :state="errors[0] ? false : null"
                          :disabled="allDisabled"
                        >
                          <span class="mr-05">ONE PAYMENT</span>
                        </b-form-radio>
                        <b-form-radio
                          class="mr-05"
                          value="2"
                          v-model="form.paymentMethod"
                          :state="errors[0] ? false : null"
                          disabled
                        >
                          <span class="mr-05">MULTIPLE P.</span>
                        </b-form-radio>
                      </div>
                    </b-input-group>
                  </validation-provider>
                </b-col>
                <b-col v-if="form.paymentMethod == 2" cols="3" class="mb-1">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <div class="d-flex justify-content-between">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text class="prepend__width">
                            <span>AMOUNT OF EACH</span>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          :state="errors[0] ? false : null"
                          type="number"
                          :value="paymentFractions.length"
                          disabled
                        />
                      </b-input-group>
                      <tabler-icon
                        v-if="
                          offerInfo.mode == 'create' ||
                          offerInfo.mode == 'pending'
                        "
                        icon="CirclePlusIcon"
                        class="text-primary cursor-pointer ml-1"
                        size="30"
                        @click="addPartialPayment"
                      />
                    </div>
                  </validation-provider>
                </b-col>
                <template v-for="(item, index) in paymentFractions">
                  <template v-if="form.paymentMethod != null">
                    <b-col cols="4" :key="index + 1">
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text
                              class="prepend__width"
                              style="width: 10rem"
                              >PAYMENT DATE</b-input-group-text
                            >
                          </template>
                          <kendo-datepicker
                            style="flex: 1"
                            :format="'MM/dd/yyyy'"
                            v-mask="'##/##/####'"
                            v-model="item.date"
                            :state="errors[0] ? false : null"
                            :class="errors[0] ? 'border-danger' : ''"
                            :disabled="allDisabled"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="4" :key="index + 2">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="
                          form.result == 'accept'
                            ? 'required|validate-amount'
                            : ''
                        "
                      >
                        <div class="d-flex justify-content-between">
                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text class="prepend__width"
                                >AMOUNT</b-input-group-text
                              >
                            </template>
                            <vue-money
                              v-model="offer.amount"
                              class="form-control"
                              v-bind="money"
                              disabled
                              :class="{
                                'border-danger': errors[0] && form._check,
                              }"
                            />
                          </b-input-group>
                          <feather-icon
                            v-if="
                              form.paymentMethod == 2 &&
                              (offerInfo.mode == 'create' ||
                                offerInfo.mode == 'pending')
                            "
                            @click="deletePartialPayment(index)"
                            class="ml-1 cursor-pointer text-danger"
                            icon="TrashIcon"
                            style="margin-top: 0.5rem"
                            size="20"
                          />
                        </div>
                      </validation-provider>
                    </b-col>
                  </template>
                </template>
              </template>
            </template>
            <template
              v-if="
                form.result == 'reject' ||
                (offerInfo.mode == 'watch' && form.status == 2)
              "
            >
              <b-col cols="3" v-if="form.result == 'reject'">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|validate-amount"
                  name="moneyA"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="prepend__width"
                        >CREDITOR OFFER</b-input-group-text
                      >
                    </template>
                    <vue-money
                      v-model="form.request"
                      class="form-control"
                      v-bind="money"
                      :disabled="allDisabled"
                      :class="{
                        'border-danger':
                          errors[0] && form._check && form.request == 0,
                      }"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col cols="3" v-if="form.result == 'reject'">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >PERCENTAGE</b-input-group-text
                    >
                  </template>
                  <b-form-input :value="_requestPercentage" disabled />
                </b-input-group>
              </b-col>

              <!-- date -->
              <b-col cols="3">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="form.result == 'rejected' ? 'required' : ''"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="prepend__width"
                        >RECONTACT DATE</b-input-group-text
                      >
                    </template>
                    <div style="flex: 1">
                      <kendo-datepicker
                        :style="
                          isDarkSkin
                            ? 'background: #222222; color: #f5f5f5'
                            : 'background:#f5f5f5; color:#222222'
                        "
                        :format="'MM/dd/yyyy'"
                        v-model="form.recontact_date"
                        :state="errors[0] ? false : null"
                        :disabled="allDisabled"
                        v-mask="'##/##/####'"
                      />
                    </div>
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="prepend__width"
                      >REASON
                    </b-input-group-text>
                  </template>
                  <!-- textarea -->

                  <b-form-textarea
                    v-model="form.reason_rejected"
                    :disabled="allDisabled"
                    rows="1"
                    max-rows="3"
                  />
                </b-input-group>
              </b-col>
            </template>
          </b-row>
        </div>
      </ValidationObserver>
      <b-row class="content-form mx-1 mt-1" v-if="isCeo && form.last_comment">
        <b-col
          cols="12"
          class="font-weight-bolder"
          style="font-size: 14px; margin-bottom: 5px"
        >
          Comment by Chief:
          <hr class="mt-0" />
        </b-col>
        <b-col>
          <div>
            {{ offerInfoData.last_comment.comment }}
          </div>
          <div class="text-right text-muted mt-2">
            {{ offerInfoData.last_comment.user_name }} |
            {{ offerInfoData.last_comment.created_at | myGlobalWithHour }}
          </div>
        </b-col>
      </b-row>
      <div class="mt-4" v-else>
        <VueStepper v-if="offerInfo.mode == 'watch'" :steps="steps" />
      </div>
      <div
        v-if="offerInfo.mode == 'create' || offerInfo.mode == 'pending'"
        class="mt-4 d-flex justify-content-center"
        style="gap: 1rem"
      >
        <b-button
          v-if="form.result == 'accept'"
          size="lg"
          variant="success"
          @click="continueProcess('create')"
          >Continue</b-button
        >
        <b-button
          v-if="form.result == null || form.result == 'reject'"
          size="lg"
          variant="success"
          @click="continueProcess('create')"
          >Continue</b-button
        >
        <b-button
          v-if="form.result == 'pending'"
          size="lg"
          variant="primary"
          @click="continueProcess('pending')"
          >Save</b-button
        >
      </div>
      <div
        v-if="
          offerInfo.mode == 'watch' &&
          status != 'REJECTED' &&
          ((cStep == 1 && isSupervisor) || (cStep == 2 && isCeo))
        "
        class="mt-2 d-flex justify-content-center"
        style="gap: 1rem"
      >
        <b-button
          size="lg"
          variant="success"
          @click="continueProcess('approve')"
          >Approve</b-button
        >
        <b-button size="lg" variant="danger" @click="continueProcess('reject')"
          >Reject</b-button
        >
      </div>
    </b-overlay>
    <b-modal
      v-model="continueInfo.modal"
      centered
      title-class="h4 font-weight-bolder text-white"
      ok-title="Send"
      no-close-on-backdrop
      :title="continueInfo.mode + ' Offer'"
    >
      <template v-if="continueInfo.mode == 'Reject'">
        <b-form-group label="Reason">
          <b-form-select
            v-model="continueInfo.selected"
            :options="continueInfo.options"
          />
        </b-form-group>
        <b-form-group label="Message">
          <b-form-textarea
            v-model="continueInfo.message"
            placeholder="Message..."
          />
        </b-form-group>
        <b-form-group v-if="isSupervisor" label="Recontact Date">
          <b-form-datepicker
            v-model="form.recontact_date"
            :style="
              isDarkSkin
                ? 'background: #222222; color: #f5f5f5'
                : 'background:#f5f5f5; color:#222222'
            "
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          />
        </b-form-group>
      </template>
      <template v-else>
        <div
          v-if="
            isCeo &&
            form.paymentFormat == 2 &&
            form.paymentType == 2 &&
            moduleId != 5
          "
        >
          <h4 class="text-left"><strong>Check details:</strong></h4>
          <b-row>
            <b-col
              cols="6"
              class="mb-1"
              v-b-tooltip.hover.top="offerInfo.creditorName"
            >
              <b-form-group label="Pay to">
                <b-form-input v-model="offerInfo.creditorName" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-1" v-b-tooltip.hover.top="memoLabel">
              <b-form-group label="Memo">
                <b-form-input v-model="memoLabel" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-1" v-b-tooltip.hover.top="checkAddress">
              <b-form-group label="Address">
                <b-form-input v-model="checkAddress" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="3" class="mb-1">
              <b-form-group label="Date">
                <b-form-input
                  v-model="paymentFractions[0].date"
                  type="date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="3" class="mb-1">
              <b-form-group label="Amount">
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="offer.amount"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-if="isCeo && form.paymentType == 1 && moduleId != 5">
          <h4 class="text-left"><strong>E-Check details:</strong></h4>
          <b-row>
            <b-col
              cols="6"
              class="mb-1"
              v-b-tooltip.hover.top="offerInfo.creditorName"
            >
              <b-form-group label="Pay to">
                <b-form-input v-model="offerInfo.creditorName" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-1" v-b-tooltip.hover.top="memoLabel">
              <b-form-group label="Memo">
                <b-form-input v-model="memoLabel" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-1">
              <b-form-group label="Date">
                <b-form-input
                  v-model="paymentFractions[0].date"
                  type="date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-1">
              <b-form-group label="Amount">
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="offer.amount"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <validation-observer ref="form-continue">
          <b-row
            class="mt-1"
            v-if="
              offerInfo.openByCeo &&
              form.type == 1 &&
              form.paymentFormat == 2 &&
              form.paymentType == 2
            "
          >
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="check_number"
                rules="required"
              >
                <b-form-group label="Check number">
                  <b-form-input v-model="form.check_number" autofocus />
                  <span v-if="errors[0]" class="text-danger">
                    Check number {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="file"
                rules="required"
              >
                <b-form-group label="Check File">
                  <b-form-file v-model="fileToUpload" @input="fileFill" />
                  <span v-if="errors[0]" class="text-danger">
                    File {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
            v-if="
              offerInfo.openByCeo && form.paymentType == 1 && form.type == 2
            "
          >
            <b-col cols="6">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-group
                  label="Bank Account"
                  label-for="bank-echeck-account"
                >
                  <v-select
                    id="bank-echeck-account"
                    v-model="eCheckbankAccountId"
                    label="account_name"
                    placeholder="Select a bank account..."
                    :options="eCheckbankAccounts"
                    :reduce="(option) => option.id"
                    style="min-width: 300px"
                    :clearable="true"
                    :class="errors.length ? 'border-danger' : ''"
                  >
                    <template v-slot:option="option">
                      <div>
                        <span class="d-block">
                          <strong>Bank Name: </strong>
                          {{ option.bank_name }}
                        </span>
                        <span class="d-block">
                          <strong> Account Name:</strong>
                          {{ option.account_name }}
                        </span>
                        <span class="d-block">
                          <strong> Account Number:</strong>
                          {{ option.account_number }}
                        </span>
                      </div>
                    </template>
                    <template #selected-option="option">
                      <div>
                        <span>{{
                          `${option.account_name} - ${option.account_number}`
                        }}</span>
                      </div>
                    </template>
                  </v-select>
                  <span
                    v-if="
                      getBankAccount.id &&
                      getBankAccount.balance < offer.amount &&
                      $refs.eCheckBalance &&
                      $refs.eCheckBalance.newBalance < offer.amount
                    "
                    class="text-danger"
                    >Balance is not enough</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="6" v-if="getBankAccount.id">
              <b-form-group label="Balance" label-for="balance-bank-account">
                <e-check-balance
                  ref="eCheckBalance"
                  :item="{
                    bank_check_account_id: getBankAccount.id,
                    balance: getBankAccount.balance,
                    bank_name: getBankAccount.bank_name,
                    account_name: getBankAccount.account_name,
                    account_number: getBankAccount.account_number,
                  }"
                  @updateBalance="getECheckBankAccounts"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Message">
            <validation-provider
              v-slot="{ errors }"
              :rules="isChief || isSupervisor ? 'required' : ''"
              name="message-support"
            >
              <b-form-textarea
                :class="{ 'border-danger': errors[0] }"
                v-model="continueInfo.message"
                placeholder="Message..."
              />
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </template>

      <template #modal-footer>
        <b-button @click="clearMessage()" variant="outline-secondary"
          >Cancel</b-button
        >
        <b-button @click="continueOk" variant="outline-primary">{{
          continueInfo.mode
        }}</b-button>
      </template>
    </b-modal>

    <new-motive-modal v-if="statusMotive" @closing="closing" />

    <new-phone-modal
      v-if="statusPhone"
      :creditors_id="form.creditorId"
      @closing="closeModalPhone"
      @close="closeModalPhone"
    />

    <new-agent-modal
      v-if="statusAgent"
      :creditors_id="form.creditorId"
      @closing="closeModalAgent"
      @close="closeModalAgent"
    />

    <essential-modal
      v-if="essentialModal"
      :account="offerInfo.account"
      :event="eventId"
      :id_analysis="offerInfo.id_analysis"
      @close="closeModalEssential"
    />
    <modal-approve-supervisor
      v-if="statusApproveSupervisor"
      :modal-approve-supervisor="statusApproveSupervisor"
      @closeModalApprove="statusApproveSupervisor = false"
      @approveSup="approveSup"
    />
    <modal-edit-creditor
      v-if="modalEditCreditorOn"
      :user-data="dataModal"
      :onlyView="allDisabled"
      @close="closeModalEditCreditorOnSave"
      @hideModal="closeModalEditCreditor"
    />

    <modal-send-request-permission
      v-if="showModalAppro"
      @close="showModalAppro = false"
      :type-permission-id="4"
      :id-table="offerInfo.creditorId"
      :client-name="offerInfo.client"
      :client-account-id="offerInfo.clientAccountId"
      :data-json="dataToApprove"
      @updatePermission="refreshVerifyStatusRequest"
    ></modal-send-request-permission>

    <modal-details-payment-customer
      v-if="openPaymentDetailCustomerOn"
      :accountData="accountData"
      :accountId="offerInfo.client_account_id"
      with-details
      @closeModal="openPaymentDetailCustomerOn = false"
    />
  </b-modal>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VMoney, Money } from "v-money";
import { amgApi } from "@/service/axios";
import vSelect from "vue-select";
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
import NewMotiveModal from "@/views/debt-solution/views/priority-offers/components/modal/NewMotiveModal.vue";
import NewPhoneModal from "@/views/debt-solution/views/settlement-offer/NewPhoneModal.vue";
import NewAgentModal from "@/views/debt-solution/views/settlement-offer/NewAgentModal.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import VueStepper from "./Stepper.vue";
import { defineAsyncComponent } from "@vue/composition-api";
import EssentialModal from "@/views/debt-solution/views/settlement-offer/EssentialModal.vue";
import ModalDetailsPaymentCustomer from "@/views/commons/components/customer-tickets/components/modal/ModalDetailsPaymentCustomer.vue";

import ModalEditCreditor from "@/views/debt-solution/views/creditors/components/modal/ModalEditCreditor.vue";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service";

import ModalSendRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import VerifyStatusRequest from "@/views/commons/components/permissions/components/VerifyStatusRequest.vue";
import OfferService from "@/views/debt-solution/views/settlement-offer/offer.service";

import RingcentralService from "@/service/commons/ringcentral.service.js";
import ChecksService from "@/views/commons/components/check-expenses/services/checks.services";
import ECheckBalance from "@/views/commons/components/check-expenses/components/ECheckBalance.vue";
export default {
  name: "SettlementOfferModal",
  directives: { money: VMoney },
  components: {
    VueApexCharts: defineAsyncComponent(() => import("vue-apexcharts")),
    vSelect,
    VueStepper,
    VueMoney: Money,
    NewMotiveModal,
    ModalApproveSupervisor,
    ModalEditCreditor,
    NewPhoneModal,
    EssentialModal,
    NewAgentModal,
    ModalSendRequestPermission,
    VerifyStatusRequest,
    ModalDetailsPaymentCustomer,
    ECheckBalance,
  },

  props: {
    show: Boolean,
    offerInfo: Object,
    eventId: null,
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eCheckbankAccountId: null,
      eCheckbankAccounts: [],
      commentReasonRejected: "",
      chartId: 0,
      selected: "first",
      options: [
        { text: "  E-CHECK (CHECKING ACCOUNT)", value: "first" },
        { text: "  CHECK(OVERNIGHT)", value: "second" },
        { text: "CASHIER CHECK", value: "third" },
        { text: "MONEY ORDER", value: "fourth" },
        // { text: "DEBIT/CREDIT CARD", value: "fifth" },
      ],
      isActive: false,
      loading: false,
      chartOptions: {
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          labels: { colors: true },
        },
        labels: ["Savings", "Creditor", "Profit"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      minPercentage: "0.00 %",
      offer: {
        balance: 0,
        amount: 0,
        saving: "",
        profit: "",
        createdAt: new Date(),
      },
      form: {
        creditorId: null,
        creditorName: null,
        contact: "",
        agent: "",
        date: moment().format("YYYY-MM-DD"),
        paymentDate: null,
        type: null,
        result: null,
        audio: null,
        sl: null,
        sCost: 0,
        request: "",
        percentage: "",
        _check: false,
        paymentType: null,
        paymentFormat: null,
        paymentMethod: null,
        paymentPartial: 1,
        account_number: null,
        check_number: null,
        check_id: null,
      },
      paymentFractions: [{ date: "", amount: "" }],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        maxlength: 12,
      },
      creditorOpts: [],
      creditorNumbers: {
        options: [],
        value: null,
      },
      creditorAgents: {
        options: [],
        value: null,
      },
      continueInfo: {
        modal: false,
        mode: "",
        options: [
          { value: null, text: "Please select an option", disabled: true },
          "Incorret Information",
          "Negotiation too hight",
          "Missing Information",
          "Poorly Done Negotiation",
          "Others",
        ],
        selected: null,
        message: "",
      },
      offerCreatedFrom: "",
      formResult: "",
      steps: [
        { name: "Waiting for supervisor approve", done: null },
        { name: "Waiting for ceo approve", done: null },
        { name: "Completed", done: null },
      ],
      cAudio: "",
      cSl: "",
      cStep: 0,
      cPaymentDate: null,
      status: "",
      creditor_id: "",
      client_account: "",
      profitPercent: 0.25,

      selectDate: {
        options: [],
        value: null,
      },
      commentary: null,
      statusMotive: false,
      statusPhone: false,
      statusAgent: false,
      lengthContactNumber: null,
      statusCommentary: false,
      statusApproveSupervisor: false,
      modalEditCreditorOn: false,
      dataModal: {},
      requiredScost: true,
      lastCreditorNumber: null,
      creditorAddressComplete: false,
      essentialModal: false,
      misSeries: [0, 100, 0],

      selectCredentialsRingCentral: {
        options: [],
        value: null,
      },

      showCalledFrom: false,
      totalCallsByCreditor: 0,
      totalCallsByNumberByCreditor: 0,
      wasApprovedCheif: {
        status: "NOT",
        exists: false,
        code: null,
      },
      showModalAppro: false,
      verifyStatusKey: 1,
      currentCreditor: null,
      accountData: {},
      openPaymentDetailCustomerOn: false,
      isDebtor: null,
      totalBalance: 0,
      cpyCreditorAccount: null,
      existCurrentCreditor: false,
      uploadFiles: [],
      fileToUpload: [],
      checkAddress: null,
      memoLabel: null,
    };
  },
  async mounted() {
    this.toggleModal("settlement-offer-modal");
    this.activeSocketReloadRequestStatus();
    if (
      this.isCeo &&
      this.form.sCost == 0 &&
      this.offerInfo.mode != "watch" &&
      this.offerInfo.mode != "pending"
    ) {
      this.requiredScost = false;
    }
  },
  beforeDestroy() {
    try {
      window.socket.unbind("permission-attended-notification-debtsolution");
    } catch (error) {
      console.log(error);
    }
  },
  async created() {
    this.importComponent();
    this.getCredentialsRingCentralByModuleId();
    this.offer.balance = this.offerInfo.balance.replace(",", "");
    this.form.account_number = this.offerInfo.account_number;
    this.cpyCreditorAccount = this.offerInfo.account_number;
    this.form.creditorName = this.offerInfo.creditorName;
    this.form.creditorId = this.offerInfo.creditorShortId;
    this.form.agent = this.offerInfo.creditorAgent;
    this.form.contact = this.offerInfo.creditorContact;
    this.memoLabel = `ACCT. ${this.offerInfo.creditorAccount} SETTLED IN FULL ${this.offerInfo.client}`;
    this.selectDate.value = null;
    await this.initialize();
    await this.getDataIsDebtor();
    await this.getDataTotalBalance();
    await this.getCheckAddress();
  },
  computed: {
    getBankAccount() {
      return (
        this.eCheckbankAccounts.find(
          (item) => item.id === this.eCheckbankAccountId
        ) || { id: null, balance: 0 }
      );
    },
    offerInfoData() {
      let item = this.form;
      item.last_comment = JSON.parse(item.last_comment);
      let comment = item.last_comment.comment.split(":");
      if (comment.length > 1) {
        item.last_comment.comment = comment[1];
      }
      return item;
    },
    _percentage() {
      const numBalance = this.offer.balance;
      const numAmount = this.offer.amount;
      if (numAmount == 0) return `${0} %`;
      const res = (numAmount / numBalance) * 100;
      return `${res.toFixed(2)} %`;
    },
    _saving() {
      const numBalance = this.offer.balance;
      const numAmount = this.offer.amount;
      const res = numBalance - numAmount;
      return `$ ${res.toFixed(2)}`;
    },
    _profit() {
      const numBalance = this.offer.balance;
      const numAmount = this.offer.amount;
      const res = (numBalance - numAmount) * this.profitPercent;
      return `$ ${res.toFixed(2)}`;
    },
    _series() {
      const numBalance = this.offer.balance;
      const numAmount = this.offer.amount;
      if (numBalance < 1 || numAmount < 1) return [0, 100, 0];

      const amg = (numBalance - numAmount) * (this.cProfitPercent || 0.25);
      const saving = numBalance - numAmount - amg;
      const creditor = numAmount;

      this.chartId++;
      return [saving, creditor, amg];
    },
    _settlement_total() {
      const numBalance = parseFloat(this.offer.balance);
      const numAmount = this.offer.amount;
      const cost = parseFloat(this.form.sCost);
      const profit = (numBalance - numAmount) * this.profitPercent;
      const total = profit + cost;
      return `$ ${total.toFixed(2)}`;
    },
    _amount_needed() {
      const numBalance = parseFloat(this.offer.balance);
      const numAmount = parseFloat(this.offer.amount);
      const cost = parseFloat(this.form.sCost);
      const profit = (numBalance - numAmount) * parseFloat(this.profitPercent);
      const total = profit + cost + numAmount;
      return `$ ${total.toFixed(2)}`;
    },
    _requestPercentage() {
      const numBalance = this.offer.balance;
      const numAmount = Number(this.form.request);
      if (numAmount == 0) return `${0} %`;
      const res = (numAmount / numBalance) * 100;
      return `${res.toFixed(2)} %`;
    },
    modalTitle() {
      if (this.offerInfo.migrate) {
        return "MIGRATING SETTLEMENT OFFER";
      }
      if (this.offerInfo.mode == "create") {
        return !this.accountBalanceIsMajorThanOfferAndProfit
          ? "NEW SETTLEMENT OFFER WITHOUT BALANCE"
          : "NEW SETTLEMENT OFFER";
      }
      return "SETTLEMENT OFFER";
    },
    allDisabled() {
      return !(
        this.offerInfo.mode == "create" || this.offerInfo.mode == "pending"
      );
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    isSupervisor() {
      return this.currentUser.role_id == 2;
    },
    isManagement() {
      return (
        this.currentUser.role_id == 1 ||
        (this.currentUser.role_id == 3 && this.moduleId == 16)
      );
    },
    offerWithoutBalance() {
      return (
        (this.offerInfo.mode == "watch" && this.form.motive_id > 0) ||
        ((this.offerInfo.mode == "create" ||
          this.offerInfo.mode == "pending") &&
          !this.accountBalanceIsMajorThanOfferAndProfit &&
          (this.form.result == "pending" || this.form.result == "accept"))
      );
    },
    profitNumber() {
      const numBalance = this.offer.balance;
      const numAmount = this.offer.amount;
      const res = (numBalance - numAmount) * this.profitPercent;
      return res.toFixed(2);
    },
    accountBalanceIsMajorThanOfferAndProfit() {
      const numProfit = Number(this.profitNumber);
      const numAmount = Number(this.offer.amount);
      const clientBalance =
        this.offerInfo?.client_balance || this.client?.balance;
      const balance = clientBalance ? Number(clientBalance) : 0;
      const plus = numProfit + numAmount;
      return balance >= plus;
    },
    sumPaymentFractionsAmount() {
      const sumPayments = this.paymentFractions.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );
      return sumPayments ? Number(sumPayments).toFixed(2) : 0.0;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      isCeo: "auth/isCeo",
      client: "DebtSolutionClients/G_CLIENTS",
      currentBreakPoint: "app/currentBreakPoint",
    }),
    deployDate() {
      return this.$moment("2023-01-14");
    },
    createsMoment() {
      return this.$moment(this.offer.createdAt);
    },
    createsMomentIsBefore() {
      return (
        this.createsMoment.isAfter(this.deployDate) ||
        (this.form.offer_id > 54 && this.offer.createdAt == null)
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    radioGroupColorBorder() {
      return this.isDarkSkin ? "radio__group_dark" : "radio__group_light";
    },
    isByMail() {
      return this.form.paymentFormat == 2;
    },
    isByPhoneOrOnline() {
      return this.form.paymentFormat == 1 || this.form.paymentFormat == 3;
    },
    isInToBeApprovedGrid() {
      return this.$route.name == "debtsolution-settlements-to-be-approved";
    },
    aPaymentFormatHasBeenSelected() {
      return !!this.form.paymentFormat;
    },
    dataToApprove() {
      let dataToApprove = [
        {
          label: "Client",
          value: this.offerInfo.client,
          section: "General Information",
        },
        {
          label: "Creditor",
          value: this.offerInfo.creditorName,
          section: "General Information",
        },
        {
          label: "Average & Settlement",
          value: this.minPercentage,
          section: "General Information",
        },
        {
          label: "Actual Balance",
          value: "$ " + this.$options.filters.currency(this.offer.balance),
          section: "Offer",
        },
        {
          label: "AMG Offer Amount",
          value: "$ " + this.$options.filters.currency(this.offer.amount),
          section: "Offer",
        },
        {
          label: "Percentage",
          value: this._percentage,
          section: "Offer",
        },
        {
          label: "Saving",
          value: this._saving,
          section: "Offer",
        },
        {
          label: "AMG Profit (25%)",
          value: this._profit,
          section: "Offer",
        },
        {
          label: "Settlement Cost",
          value: this.$options.filters.currency(this.form.sCost),
          section: "Offer",
        },
        {
          label: "Payment Date",
          value: this.paymentFractions[0].date,
          section: "Offer",
        },
        {
          label: "Reason",
          value: this.selectDate.options.find(
            (object) => object.motive_id === this.selectDate.value
          ).motive,
          section: "Offer",
        },
      ];
      return dataToApprove;
    },
    getIsDebtor() {
      return this.isDebtor;
    },
    getTotalBalance() {
      return this.totalBalance;
    },
    getNewBalance() {
      const numBalance = parseFloat(this.offer.balance);
      const numAmount = this.offer.amount;
      const cost = parseFloat(this.form.sCost);
      const profit = (numBalance - numAmount) * this.profitPercent;
      const total = profit + cost + numAmount;
      return this.totalBalance - total;
    },
  },

  methods: {
    async getECheckBankAccounts() {
      try {
        const data = await ChecksService.getECheckBankAccounts();
        this.eCheckbankAccounts = data;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
        throw error;
      }
    },
    async getCheckAddress() {
      try {
        this.addPreloader();
        const result = await CreditorsService.EDIT_CREDITORS({
          id: this.form.creditorId,
        });
        const { mailing_address, city, state_slug, zip_code } = result[0];
        let validateZip = zip_code == null ? "" : zip_code;
        this.checkAddress = `${mailing_address} ${city}, ${state_slug} ${validateZip}`;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async initialize() {
      if (this.offerInfo.profitPercent == null) {
        this.profitPercent = 0.25;
      } else {
        this.profitPercent = Number(this.offerInfo.profitPercent) / 100;
      }
      this.isActive = true;
      if (this.offerInfo.mode == "watch" || this.offerInfo.mode == "pending") {
        await this.getOffer();
        await this.getCreditorPercent(this.form.offer_creditor);

        this.creditorNumbers.options.forEach((element) => {
          const Numberparts = element.phone.split("/");
          const cleanNumber = Numberparts[0].trim();

          const NumberFormParts = this.form.contact.split("/");
          const cleanNumberForm = NumberFormParts[0].trim();

          if (cleanNumber == cleanNumberForm) {
            this.creditorNumbers.value = element.phone_id;
          }
        });

        this.creditorAgents.options.forEach((element) => {
          const Agentparts = element.agent.split("/");
          const cleanAgent = Agentparts[0].trim();

          const AgentFormParts = this.form.agent.split("/");
          const cleanAgentForm = AgentFormParts[0].trim();

          if (cleanAgent == cleanAgentForm) {
            this.creditorAgents.value = element.agent_id;
          }
        });

        await this.getOfferMotive(this.form.motive_id);
        // await this.getOfferMotives();
      } else {
        await this.getCreditorPercent(this.offerInfo.creditor_id);
        await this.getOfferMotives();
      }
      await this.getCurrentCreditorByDsListCreditId();
      if (this.offerInfo.migrate) {
        this.form.result = "accept";
      }
    },
    essentialModalOpen() {
      this.essentialModal = true;
    },
    selectDateChange(item) {
      if (
        this.selectDate.options[this.selectDate.options.length - 1].motive_id !=
        item
      ) {
        this.statusCommentary = false;
      } else {
        this.statusCommentary = true;
      }
    },
    activeSocketReloadRequestStatus() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind(
          "permission-attended-notification-debtsolution",
          async (response) => {
            if (response.user_receiver == this.currentUser.user_id) {
              this.verifyStatusKey++;
            }
          }
        );
      } catch (error) {
        console.log("Socket-settlement", error);
      }
    },
    clearMessage() {
      this.continueInfo.message = "";
      this.continueInfo.selected = null;
    },
    ...mapActions({
      A_COUNTER_OFFERS_PENDING:
        "DebtSolutionSettlementOfferStore/A_COUNTER_OFFERS_PENDING",
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    ...mapMutations({
      M_UPDATE_TO_BE_APPROVED_GRID:
        "DebtSolutionSettlementOfferStore/M_UPDATE_TO_BE_APPROVED_GRID",
      M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL:
        "DebtSolutionSettlementOfferStore/M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL",
    }),
    unimportComponent() {
      // Remove the 'Vue' component
      delete this.$options.components.VueApexCharts;
    },
    async importComponent() {
      if (!this.$options.components.VueApexCharts) {
        import("vue-apexcharts").then((VueApexCharts) => {
          this.$options.components.VueApexCharts = VueApexCharts;
        });
      }
    },
    async sendOffersMessage(type) {
      const params = {
        module_id: 5,
        creditor_account: this.offerInfo.creditorAccount
          ? this.offerInfo.creditorAccount
          : this.offerInfo.account,
        offer_id: this.offerInfo.id,
        type_offer: type, // 1: APPROVED 2: REJECT
        creditor_id: this.offerInfo.creditor_id,
        client_account_id:
          this.offerInfo.client_account_id || this.offerInfo.clientAccountId,
      };
      const response = await SettingsService.sendOffersMessage(params);
      if (response.status === 200) {
        this.showSuccessSwal();
      }
    },
    handleHidden() {
      this.unimportComponent();
      this.isActive = false;
      this.loading = false;
      this.offer = {
        id: null,
        balance: "0",
        amount: "0",
        percentage: "",
        saving: "",
        profit: "",
      };
      this.formResult = this.form.result;
      this.form = {
        creditorId: null,
        creditorName: "",
        contact: "",
        agent: "",
        date: moment().format("YYYY-MM-DD"),
        paymentDate: null,
        type: null,
        result: null,
        audio: null,
        sl: null,
        request: "",
        percentage: "",
        _check: false,
        reason_rejected: null,
        recontact_date: null,
      };
      this.creditorOpts = [];
      this.steps = [
        { name: "Waiting for supervisor approve", done: false },
        { name: "Waiting for CEO approve", done: false },
        { name: "Approved", done: false },
      ];
      this.minPercentage = "0.00 %";
      this.$emit("closing");
    },
    async submit() {
      try {
        this.addPreloader();
        this.form._check = true;
        const result = this.selectDate.options.find(
          (object) => object.motive_id === this.selectDate.value
        );
        const motiveName = result ? result.motive : null;

        const valid = await this.$refs.cform.validate();
        if (!valid) return;

        let createdFrom = "";
        if (this.$route.meta.offerCreatedFrom === "priority-offers") {
          // if it was created from priority offers
          createdFrom = this.$route.meta.hasBalance === 1 ? "3" : "4"; // priority offers balance | no balance
        } else {
          createdFrom = this.offerWithoutBalance ? "2" : "1";
          // 2: if it was created from dashboard and the status is late payment (1) or charge off (2)
          // 1: if it was created from dashboard and the status is ready to settle
        }

        this.loading = true;
        const form = new FormData();
        form.append("check_file", this.form.file);
        form.append("check_number", this.form.check_number);
        form.append("user_id", this.currentUser.user_id);
        form.append("offer_id", this.offerInfo.id);
        form.append("creditor_id", this.offerInfo.creditorId);
        // si existe this.form.offer_creditor
        form.append(
          "offer_creditor",
          this.form.offer_creditor
            ? this.form.offer_creditor
            : this.form.creditorId
        );
        form.append(
          "creditor_account",
          this.cpyCreditorAccount != this.form.account_number
            ? this.form.account_number
            : this.existCurrentCreditor
            ? this.cpyCreditorAccount
            : null
        );
        form.append("created_from", createdFrom);
        form.append(
          "client_account",
          this.$route.params.idClient
            ? this.$route.params.idClient
            : this.offerInfo.client_account_id || this.offerInfo.clientAccountId
        );
        // de creditrosNumbers.options me ponga el phone donde phone_id es igual a creditrosNumbers.value

        this.form.contact = this.creditorNumbers.options.find(
          (object) => object.phone_id === this.creditorNumbers.value
        ).phone;
        form.append("contact_number", this.form.contact);

        this.form.agent = this.creditorAgents.options.find(
          (object) => object.agent_id === this.creditorAgents.value
        ).agent;
        form.append("agent", this.form.agent);
        form.append("balance", this.offer.balance);
        form.append("offer_amount", this.offer.amount);
        form.append("saving", this._saving.slice(2));
        form.append("profit", this._profit.slice(2));
        form.append("profit_percent", this.profitPercent);

        let formattedPaymentFractions = null;
        if (this.paymentFractions) {
          formattedPaymentFractions = this.paymentFractions.map((fraction) => {
            return {
              ...fraction,
              date: moment(fraction.date).format("YYYY-MM-DD"),
            };
          });
        }

        form.append(
          "payment_fractions",
          JSON.stringify(formattedPaymentFractions)
        );

        form.append("payment_type", this.form.paymentType);
        form.append("payment_method", this.form.paymentMethod);
        form.append("payment_format", this.form.paymentFormat);
        form.append("offer_date", this.form.date);
        form.append("request_type", this.form.type);
        form.append("comment", this.continueInfo.message || ".");
        form.append("result", this.form.result);
        form.append("payment_date", this.cPaymentDate);
        form.append("settlement_cost", this.form.sCost || 0);
        if (this.form.result == "accept" || this.form.result == "pending") {
          form.append("reason_rejected", null);
          form.append("recontact_date", null);
          form.append("audio_file", this.form.audio);
          form.append("doc_file", this.form.sl);
        } else {
          form.append("reason_rejected", this.form.reason_rejected);
          form.append("recontact_date", this.form.recontact_date);
          form.append("offer_request", Number(this.form.request));
        }
        if (this.offerInfo.migrate) {
          form.append("is_migration", true);
        }

        if (this.offerWithoutBalance) {
          form.append("motive_id", this.selectDate.value);
          if (this.statusCommentary) {
            form.append("commentary", this.commentary);
          }
        }

        if (this.form.result == "accept") {
          form.append(
            "call_log_phone_id",
            this.selectCredentialsRingCentral.value
          );
        }
        const opts = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        let calledFrom =
          this.form.result == "accept"
            ? this.selectCredentialsRingCentral.options.find(
                (object) =>
                  object.credentials_id ===
                  this.selectCredentialsRingCentral.value
              ).credentials_number
            : "";

        let content = `<strong>Offer Made:</strong><br>
        Balance: $ ${this.offerInfo.balance},<br>
        Amount: $ ${this.offer.amount} (${this._percentage}),<br>
        Account: ${this.offerInfo.creditorName}( #. ${
          this.offerInfo.account_number
        }),<br>
        ${
          this.form.creditorName != this.offerInfo.creditorName
            ? `Creditor:${this.form.creditorName},<br>`
            : ""
        }
        Date: ${moment(this.form.date).format("MM/DD/YYYY")},<br>
        Contact number: ${this.form.contact},<br>
        Agent: ${this.form.agent},<br>
        Result:<strong class="text-uppercase"> ${this.form.result}</strong>,<br>
        Called From: ${calledFrom},<br>
        Message: ${this.continueInfo.message}<br><br>`;
        // Offer Percentage: ${this._percentage} <br>
        if (this.form.result == "reject") {
          content += `Request Amount: ${this.form.request} (${this._requestPercentage}) <br><br>`;
        }
        if (this.offerWithoutBalance) {
          content += "Observation: Offer created without balance <br><br>";
          content += `Motive: ${motiveName} <br><br>`;
        }
        const noteBody = {
          date: this.form.date,
          content,
          account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.offerInfo.client_account_id ||
              this.offerInfo.clientAccountId,
          user_id: this.currentUser.user_id,
          type: 3,
          modul: this.moduleId,
          status_note: 2,
        };

        await amgApi.post("/debt-solution/insert-offer", form, opts);

        /* Update CreateSettlementGridModal when a offer is inserted */
        this.M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL(true);

        if (this.form.result == "accept" || this.form.result == "reject") {
          /* Update 'TO BE APPROVED' grid when a accepted offer is insert */
          if (this.form.result == "accept") {
            this.isInToBeApprovedGrid
              ? this.M_UPDATE_TO_BE_APPROVED_GRID(true)
              : "";
          }

          await amgApi.post("/credit-expert/add-note-credit", noteBody);
        }
        // if the motive is 4,  the result is accepted and is without balance (1,2), it will send the message
        if (
          this.selectDate.value == 4 &&
          this.form.result == "accept" &&
          [1, 2].includes(this.offerInfo.accountStatusDsNum)
        ) {
          // Message motive 4
          await this.sendOffersMessage(1); // type 1 is approved
        }
        // if the offer has balance (3) and the result is rejected, it will send the message
        if (
          this.offerInfo.accountStatusDsNum === 3 &&
          this.form.result == "reject"
        ) {
          await this.sendOffersMessage(2); // type 2 is rejected
        }
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Successfully process"
        );
        this.$emit("refresh");
      } catch (e) {
        this.showErrorSwal(e.response?.data?.message);
      } finally {
        this.handleHidden();
        this.$emit("reload");
        this.removePreloader();
      }
      this.A_COUNTER_OFFERS_PENDING({
        user_id: this.currentUser.user_id,
      });
    },
    searchCreditors: _.debounce(async function (search) {
      if (search.length < 1 || search == undefined) return;
      const res = await amgApi.post(
        "sales-made/debt-solution/search-creditors",
        {
          q: search,
        }
      );
      this.creditorOpts = res.data;
    }, 500),

    selectCreditor(item) {
      if (item == null) {
        this.form.creditorId = -1;
        this.form.creditorName = "";
        return;
      }
      this.form.creditorId = item.id;
      this.creditorAddressComplete =
        item.id_state != null &&
        item.mailing_address != null &&
        item.mailing_address != "";

      this.form.creditorName = item.value;
      this.form.offer_creditor = item.id;

      this.creditorNumbers.value = null;
      this.creditorNumbers.options = [];

      this.creditorAgents.value = null;
      this.creditorAgents.options = [];

      this.getCreditorPercent(item.id);

      this.minPercentage = `${
        (item.avg_percent ? parseFloat(item.avg_percent).toFixed(2) : 0) || 0
      } %`;
    },
    async continueProcess(process) {
      let valid;
      if (process == "approve" || process == "reject") {
        valid = true;
      } else {
        valid = await this.$refs.cform.validate();
      }
      this.form._check = true;
      if (
        (this.offerInfo.mode == "create" || this.offerInfo.mode == "pending") &&
        this.paymentFractions != null
      ) {
        this.paymentFractions.forEach((obj) => {
          obj.amount = this.offer.amount;
        });
      }
      if (!valid) {
        return;
      }

      switch (process) {
        case "approve":
          if (this.isCeo && this.form.paymentType == 1 && this.form.type == 2) {
            await this.getECheckBankAccounts();
          }
          this.continueInfo.mode = "Approve";
          this.continueInfo.modal = true;
          break;
        case "reject":
          this.continueInfo.mode = "Reject";
          this.continueInfo.modal = true;
          break;
        case "create":
          this.continueInfo.mode = "Create";
          this.continueInfo.modal = true;
          break;
        case "pending":
          this.continueInfo.mode = "Pending";
          // this.continueInfo.modal = true;z
          await this.submit();
          break;
      }
    },
    async continueOk() {
      this.commentReasonRejected =
        this.continueInfo.mode + ": " + this.continueInfo.message;
      switch (this.continueInfo.mode) {
        case "Reject":
          this.form.reason_rejected = this.continueInfo.selected;
          await this.reviewOffer(false);
          if (![2, 4].includes(this.offerCreatedFrom)) {
            // if it was created without balance (from client dashboard without balance: 2 or priority offers without balance 4)
            await this.sendOffersMessage(2);
          }
          break;
        case "Approve":
          const valid = await this.$refs["form-continue"].validate();
          if (!valid) {
            return;
          }
          if (
            this.isCeo &&
            this.form.paymentType == 1 &&
            this.form.type == 2 &&
            this.getBankAccount.balance < this.offer.amount
          ) {
            return;
          }
          await this.reviewOffer(true);
          if (this.isCeo && ![2, 4].includes(this.offerCreatedFrom)) {
            await this.sendOffersMessage(1);
          }
          break;
        case "Create":
          if (this.isAdvisor && this.offerWithoutBalance) {
            // this.statusApproveSupervisor = true; //
            if (this.wasApprovedCheif.status === "APPROVED") {
              await this.submit();
            } else {
              if (this.wasApprovedCheif.exists) {
                this.showToast(
                  "danger",
                  "top-right",
                  "Validate error",
                  "XIcon",
                  `Permission Action ${
                    this.wasApprovedCheif.status === "REJECTED"
                      ? "Was Rejected"
                      : "In Progress"
                  }`
                );
              } else {
                if (
                  this.form.type == 1 &&
                  form.paymentFormat == 2 &&
                  form.paymentType == 2
                ) {
                  await this.submit();
                } else {
                  this.showModalAppro = true;
                }
              }
            }
          } else {
            const valid = await this.$refs["form-continue"].validate();
            if (!valid) {
              return;
            }
            await this.submit();
          }
          break;
      }
    },
    // Supervisor password correct
    async approveSup() {
      await this.submit();
    },
    addPartialPayment() {
      this.paymentFractions.push({
        date: "",
        amount: "",
      });
    },
    deletePartialPayment(index) {
      this.paymentFractions.splice(index, 1);
    },
    async getOffer() {
      this.loading = true;
      const res = await amgApi.post("/debt-solution/get-offer", {
        offer_id: this.offerInfo.id,
      });
      if (res.status != 200) {
        this.$swal.fire({
          icon: "error",
        });
      }
      if (res.data.call_log_phone_id) {
        this.selectCredentialsRingCentral.value = parseInt(
          res.data.call_log_phone_id
        );
      }
      this.form.last_comment = res.data.last_comment;
      this.form.offer_id = res.data.offer_id ? res.data.offer_id : null;
      this.form.motive_id = res.data.motive_id;
      this.offer.balance = res.data.balance;
      this.offer.amount = Number(res.data.offer_amount);
      this.offerCreatedFrom = res.data.created_from_num;
      this.offer.createdAt = res.data.created_at;
      this.form.contact = res.data.contact_number;
      this.form.agent = res.data.agent;
      this.form.offer_creditor = res.data.offer_creditor;
      this.form.date = res.data.offer_date;
      this.form.type = res.data.request_type == "AMG" ? 1 : 2;
      if (res.data.result == 1) {
        this.form.result = "accept";
      } else if (res.data.result == 2) {
        this.form.result = "reject";
      } else {
        this.form.result = "pending";
      }
      this.form.creditorName = res.data.creditor_name;
      this.form.sCost = res.data.settlement_cost; //----
      if (this.isCeo && this.form.sCost == 0) {
        this.requiredScost = false;
      }
      this.form.paymentType = res.data.payment_type;
      this.form.paymentFormat = res.data.payment_format;
      this.form.paymentMethod = res.data.payment_method;
      this.paymentFractions = res.data.payment_fractions
        ? JSON.parse(res.data.payment_fractions)
        : null;
      this.creditor_id = res.data.creditor_id;
      this.client_account = res.data.client_account;
      this.form.reason_rejected = res.data.reason_rejected;
      this.form.recontact_date = res.data.recontact_date;
      this.form.status = res.data.status;

      // this.form.request = `$ ${res.data.offer_request || "00.00"}`;
      this.form.request = res.data.offer_request;

      this.cAudio = res.data.audio_url;
      this.cSl = res.data.document_url;
      this.cPaymentDate = res.data.payment_date;
      this.status = res.data.status;
      this.creditorAddressComplete =
        res.data.id_state != null &&
        res.data.mailing_address != null &&
        res.data.mailing_address != "";
      this.minPercentage = `${
        (res.data.avg_percent
          ? parseFloat(res.data.avg_percent).toFixed(2)
          : 0) || 0
      } %`;

      this.cStep = res.data.step;
      this.setSteps(res.data.step);

      this.loading = false;
    },
    async reviewOffer(accept) {
      try {
        this.addPreloader();
        const res = await amgApi.post("/debt-solution/update-offer", {
          offer_id: this.offerInfo.id,
          user_id: this.currentUser.user_id,
          creditor_id: this.creditor_id,
          client_account: this.client_account,
          is_accepted: accept,
          comment: this.commentReasonRejected,
          settlement_cost: this.form.sCost,
          recontact_date: this.form.recontact_date,
          reason_rejected: this.commentReasonRejected,
          check_number: this.form.check_number,
          check_file: this.form.file,
          open_by_ceo: this.offerInfo.openByCeo ?? false,
          namedoc: this.fileToUpload.name,
          bank_echeck_account_id: this.eCheckbankAccountId,
        });
        if (res.status == 200) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.refreshExpensesCounter(true);
          this.$emit("reload");
          this.handleHidden();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    setSteps(step) {
      this.steps = [
        { name: "Waiting for supervisor approve", done: null },
        { name: "Waiting for ceo approve", done: null },
        { name: "Completed", done: null },
      ];

      switch (step) {
        case 0:
          this.steps[0].name = "Offer Rejected";
          this.steps[0].done = false;
          break;
        case 1:
          this.steps[0].done = true;
          break;
        case 2:
          this.steps[0].done = true;
          this.steps[1].done = true;
          break;
        case 3:
          this.steps[0].done = true;
          this.steps[1].done = true;
          this.steps[2].done = true;
          break;
        case 4:
          this.steps[0].done = true;
          this.steps[1].done = false;
          this.steps[1].name = "Rejected by supervisor";
          break;
        case 5:
          this.steps[0].done = true;
          this.steps[1].done = true;
          this.steps[2].done = false;
          this.steps[2].name = "Rejected by CEO";
          break;
      }
    },
    async getCreditorPercent(id_creditor) {
      // only when creating
      this.loading = true;
      const route = "sales-made/debt-solution/search-creditor";
      const { data } = await amgApi.post(route, { q: id_creditor });
      const creditor = data[0];

      if (this.$route.meta.offerCreatedFrom == "priority-offers") {
        this.form = { ...this.form, creditorId: this.offerInfo.creditor_id };
      }
      this.loading = false;
      this.creditorAddressComplete =
        creditor.id_state != null &&
        creditor.mailing_address != null &&
        creditor.mailing_address != "";

      if (creditor.contact_number) {
        this.putPhonesInInput(creditor.contact_number);
      }
      if (creditor.agent) {
        this.putAgentsInInput(creditor.agent);
      }

      const match = data.find((creditor) => creditor.id == id_creditor);

      if (match == undefined || typeof match.avg_percent !== "string") return;

      this.minPercentage = `${
        (match.avg_percent ? parseFloat(match.avg_percent).toFixed(2) : 0) || 0
      } %`;
    },
    async fileFill() {
      this.form.namedoc = this.fileToUpload.name;
      this.form.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.form.file = file.target.result;
      };
    },
    putPhonesInInput(contact_number) {
      if (contact_number != null || contact_number != "") {
        const phones = JSON.parse(contact_number);

        const options = phones
          .map((phone, index) => {
            const phoneValue = this.validateInput(phone.phone);
            return {
              phone_id: index,
              phone: `${phoneValue}`,
            };
          })
          .filter((item) => item.phone !== "");

        if (options.length == 0) {
          options.push({
            phone_id: 0,
            phone: "",
          });
        }
        const result = {
          options: options,
          value: options[options.length - 1].phone_id,
        };

        if (this.validateInput(result.options[0].phone) == "") {
          result.options = [];
          result.value = null;
          this.creditorNumbers = result;
        } else {
          this.creditorNumbers = result;
        }
      } else {
        const result = {
          options: [],
          value: null,
        };
        this.creditorNumbers = result;
      }
    },

    //select agent
    putAgentsInInput(agent) {
      if (agent != null || agent != "") {
        const agents = JSON.parse(agent);

        const options = agents
          .map((agent, index) => {
            const agentValue = this.validateInput(agent.agent);
            const extension = this.validateInput(agent.extension);

            return {
              agent_id: index,
              agent: agentValue !== "" ? `${agentValue} ${extension}` : "",
            };
          })
          .filter((item) => item.agent !== "");

        if (options.length == 0) {
          options.push({
            agent_id: 0,
            agent: "",
          });
        }

        const result = {
          options: options,
          value: options[options.length - 1].agent_id,
        };
        if (this.validateInput(result.options[0].agent) == "") {
          result.options = [];
          result.value = null;
          this.creditorAgents = result;
        } else {
          this.creditorAgents = result;
        }
      } else {
        const result = {
          options: [],
          value: null,
        };
        this.creditorAgents = result;
      }
    },

    validateInput(obj) {
      return obj &&
        obj.trim() !== "" &&
        obj !== "null" &&
        obj !== null &&
        obj !== "undefined" &&
        obj !== undefined
        ? obj
        : "";
    },

    // select credentials ring central
    async getCredentialsRingCentralByModuleId() {
      try {
        const { data } = await RingcentralService.getCredentialsByModuleId(5);
        if (data.length > 0) {
          const options = data
            .map((item, index) => {
              const number = this.validateInput(item.number_format);
              const extension = this.validateInput(item.extension);
              return {
                credentials_id: item.id,
                credentials_number:
                  number !== ""
                    ? `${number} ${extension == "" ? "" : "/ " + extension}`
                    : "",
              };
            })
            .filter((item) => item.credentials_number !== "");

          const result = {
            options: options,
          };
          this.selectCredentialsRingCentral = result;
        } else {
          const result = {
            options: [],
            value: null,
          };
          this.selectCredentialsRingCentral = result;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    selectCredentialsRingCentralChange() {
      this.getTotalCallLogByCreditor(this.selectCredentialsRingCentral.value);
    },

    async getTotalCallLogByCreditor(phone_id) {
      try {
        const { data } = await amgApi.post(
          "/debt-solution/get-total-calls-by-creditor",
          {
            creditor_id: this.form.offer_creditor
              ? this.form.offer_creditor
              : this.form.creditorId,
            phone_id: phone_id
              ? phone_id
              : this.selectCredentialsRingCentral.value,
          }
        );
        this.totalCallsByNumberByCreditor = data.total_calls_by_number;
        this.totalCallsByCreditor = data.total_calls;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    // Selected credit motive
    async getOfferMotive(item) {
      if (item) {
        this.getOfferMotives();
        const params = { motive_id: item };
        const { data } = await PriorityOffersService.getOfferMotive(params);
        this.selectDate.options.forEach((element) => {
          if (element.motive === data[0].motive) {
            element.motive_id = item;
          }
        });
        this.selectDate.value = item;
        this.commentary = data[0].commentary;
        this.statusCommentary = data[0].motive == "Other";
      } else {
        this.getOfferMotives();
      }
    },
    // List motives
    async getOfferMotives() {
      const { data } = await PriorityOffersService.getOfferMotives();
      this.selectDate.options = data;
      this.selectDate.options.push({
        motive_id: 0,
        motive: "Other",
        commentary: "",
      });
    },

    // Close modal
    closing(item) {
      if (item == 1) {
        this.statusMotive = false;
        this.getOfferMotives();
      } else this.statusMotive = false;
      if (item == 2) {
        this.statusApproveSupervisor = false;
      }
    },
    closeModalEditCreditor() {
      this.modalEditCreditorOn = false;
    },
    closeModalEditCreditorOnSave(idState, creditorAddress) {
      this.modalEditCreditorOn = false;
      this.creditorAddressComplete =
        idState != null && creditorAddress != null && creditorAddress != "";
    },
    async openModalEditCreditor() {
      try {
        this.addPreloader();
        const result = await CreditorsService.EDIT_CREDITORS({
          id: this.form.creditorId,
        });
        this.dataModal = {
          // Basic Information
          id: result[0].id,
          parentName: result[0].parent_name,
          creditorName: result[0].value,
          creditorAddress: result[0].address,
          creditorCity: result[0].city,
          creditorState: result[0].id_state,
          creditorZipCode: result[0].zip_code,
          creditorCountry: result[0].id_country,
          parent_id: result[0].parent_id,
          creditorMailingAddress: result[0].mailing_address,
          city_address: result[0].city_address,
          state_address: result[0].id_state_address,
          zip_code_address: result[0].zip_code_address,
          country_address: result[0].id_country_address,
          // Recovery Information
          rName: result[0].name_recovery,
          recovery_phone:
            result[0].recovery_phone == null
              ? [{ id: 0, phone: "" }]
              : JSON.parse(result[0].recovery_phone),
          recovery_agent:
            result[0].recovery_agent == null
              ? [{ id: 0, agent: "", extension: "" }]
              : JSON.parse(result[0].recovery_agent),
          recovery_phone_delete: [],
          recovery_agent_delete: [],
          recoveryFax:
            result[0].fax == null ? [{ fax: "" }] : JSON.parse(result[0].fax),
          recoveryEmail:
            result[0].email == null
              ? [{ email: "" }]
              : JSON.parse(result[0].email),
          // Customer Information
          customer_name: result[0].customer_name,
          customerFax:
            result[0].customer_faxs == null
              ? [{ fax: "" }]
              : JSON.parse(result[0].customer_faxs),
          customerEmail:
            result[0].customer_emails == null
              ? [{ email: "" }]
              : JSON.parse(result[0].customer_emails),
          customerPhone:
            result[0].phone == null
              ? [{ phone: "" }]
              : JSON.parse(result[0].phone),
          agent: result[0].agent,
          offer_contact_number: result[0].offer_contact_number,
          settlement: result[0].settlement,
          settle: result[0].settle,
          website: result[0].website,
          // More Information
          timezone_id: result[0].timezone_id,
          payment_type: result[0].payment_type,
          agency: result[0].agency,
          creditor_website: result[0].creditor_website,
          average_trade_percentage: result[0].average_trade_percentage,
          interest_rate: result[0].interest_rate,
          send_types:
            result[0].send_types == null
              ? []
              : JSON.parse(result[0].send_types),
          debt_types:
            result[0].debt_types == null
              ? []
              : JSON.parse(result[0].debt_types),
          // Schedule Contact
          schedule:
            result[0].schedule == null ? [] : JSON.parse(result[0].schedule),
        };
        this.modalEditCreditorOn = true;
      } catch (error) {
        console.log(
          "Something went wrong on openModalEditCreditor in SettlementOfferModal",
          error
        );
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      } finally {
        this.removePreloader();
      }
    },
    closeModalPhone(phone) {
      if (phone == null) {
        this.statusPhone = false;
      } else {
        this.statusPhone = true;
        this.statusPhone = false;
        //select contact number
        this.creditorNumbers.options.push({
          phone_id: this.creditorNumbers.options.length,
          phone: phone,
        });
        this.creditorNumbers.value = this.creditorNumbers.options.length - 1;
      }
    },
    closeModalAgent(agent) {
      if (agent == null) {
        this.statusAgent = false;
      } else {
        this.statusAgent = true;
        this.statusAgent = false;
        //select agent
        this.creditorAgents.options.push({
          agent_id: this.creditorAgents.options.length,
          agent: agent,
        });
        this.creditorAgents.value = this.creditorAgents.options.length - 1;
      }
    },
    closeModalEssential() {
      this.essentialModal = false;
    },

    refreshVerifyStatusRequest() {
      this.$refs.verifyStatusRequest.getVerifyStatusRequest();
      this.showModalAppro = false;
    },
    async getCurrentCreditorByDsListCreditId() {
      const { data } = await OfferService.getCurrentCreditorByDsListCreditId({
        creditor_id: this.offerInfo.creditorId,
      });
      if (data.length > 0) {
        this.currentCreditor = data[0];
        this.form.creditorId = this.currentCreditor.current_credit_id;
        this.creditorAddressComplete =
          this.currentCreditor.id_state != null &&
          this.currentCreditor.mailing_address != null &&
          this.currentCreditor.mailing_address != "";
        this.form.creditorName = this.currentCreditor.current_credit_name;
        this.form.offer_creditor = this.currentCreditor.current_credit_id;
        this.form.account_number =
          this.currentCreditor.current_creditor_account_number == null
            ? this.form.account_number
            : this.currentCreditor.current_creditor_account_number;
        this.cpyCreditorAccount =
          this.currentCreditor.current_creditor_account_number == null
            ? this.cpyCreditorAccount
            : this.currentCreditor.current_creditor_account_number;
        this.existCurrentCreditor = true;
        this.getCreditorPercent(this.currentCreditor.current_credit_id);
      }
    },
    openPaymentModal() {
      this.accountData = {
        account: this.offerInfo.account_name,
        client_account_id:
          this.offerInfo.client_account_id || this.offerInfo.clientAccountId,
        transactions: {
          state_lp: null,
        },
      };
      this.openPaymentDetailCustomerOn = true;
    },
    async getDataIsDebtor() {
      try {
        const params = {
          client_account_id: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.offerInfo.client_account_id ||
              this.offerInfo.clientAccountId,
        };
        const { data } = await SettingsService.getIsDebtor(params);
        this.isDebtor = data[0].is_debtor;
      } catch (error) {
        console.log(error);
      }
    },
    async getDataTotalBalance() {
      try {
        const params = {
          client_account_id: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.offerInfo.client_account_id ||
              this.offerInfo.clientAccountId,
        };
        const { data } = await SettingsService.getTotalBalance(params);
        this.totalBalance = data[0].total;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "form.result": function (actual) {
      if (actual == "accept") {
        this.form.paymentDate = null;
        this.form.audio = null;
        this.form.sl = null;
        this.getTotalCallLogByCreditor();
      }
    },
    "form.paymentMethod": function (newVal) {
      if (
        newVal == 1 &&
        (this.offerInfo.mode == "create" || this.offerInfo.mode == "pending")
      ) {
        if (this.paymentFractions == null) {
          this.paymentFractions = [{ date: "", amount: "" }];
        }
      }
    },
  },
};
</script>
<style lang="scss">
.settlement-cost-append {
  width: 105px !important;

  .input-group-text {
    padding: 0 !important;
    width: 100% !important;

    .custom-switch {
      padding-left: 5px !important;
      padding-top: 5px !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";

.select__creditor {
  flex: 1;
}

.v-agent {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
@media (min-width: 1000px) {
  .v-agent {
    max-width: 30px !important;
  }
}

@media (min-width: 1200px) {
  .v-agent {
    max-width: 60px !important;
  }
}
@media (min-width: 1700px) {
  .v-agent {
    max-width: 80px !important;
  }
}

.mr-05 {
  margin-left: 0.5rem;
}

.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}

.prepend__width {
  justify-content: start;
  width: 10rem;
  font-size: 12px;
}

.radio__group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: none;
  border: 1px solid #d8d6de !important;
  border-radius: 0 5px 5px 0;
  flex: 1;
}

.dark-layout .radio__group {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.radio__group:focus-within {
  border: 1px solid #0090e7 !important;
  box-shadow: none !important;
}

.select__creditor:focus-within {
  border: 1px solid #0090e7 !important;
  box-shadow: none !important;
  border-radius: 0 5px 5px 0;
}

.radio__group_dark {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: none;
  border-radius: 5px 5px 5px 5px;
  flex: 1;
  border: 1px solid #ffffff66 !important;
}

.radio__group_light {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: none;
  border-radius: 0 5px 5px 0;
  flex: 1;
  border: 1px solid #d8d6de !important;
}

.radio__group--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}

.border-checkbox-error {
  border: 1px solid #fc424a !important;
  border-radius: 5px !important;
}
.selected-result {
  background-color: #007bff !important;
  color: #fff !important;
}
.content-form {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(155, 155, 155, 1);
}
</style>
