<template>
  <b-modal
    ref="newAgentModal"
    title="Add New Agent"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <b-form-group label="Agent:">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1|max:255"
            >
              <b-form-input
                v-model="agent"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Agent {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Extension:">
            <validation-provider v-slot="{ errors }" rules="min:2">
              <b-form-input
                v-model="extension"
                trim
                v-mask="'/ ######'"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Extension {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="saveAgent()" />
    </template>
  </b-modal>
</template>
<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    creditors_id: null,
  },
  data() {
    return {
      agent: "",
      extension: "",
    };
  },
  mounted() {
    this.toggleModal("newAgentModal");
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async saveAgent() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const extension = this.extension.slice(2);
          const response = await this.showConfirmSwal(`Are you sure the agent <strong>${this.agent}</strong> and extension <strong>${extension}</strong> is correct?`);
          if (response.isConfirmed) {
            this.addPreloader();
            const extAgent = this.agent + this.extension;
            const data = await PriorityOffersService.setNewAgent({
              creditors_id: this.creditors_id,
              agent: this.agent,
              extension: this.extension,
            });
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Successfully process"
            );
            this.$emit("closing", extAgent);
          }
        }
      } catch (error) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped></style>
