<template>
  <ol class="c-stepper">
    <li
      class="c-stepper__item"
      :class="{
        'c-stepper__item--approved': step.done == true,
        'c-stepper__item--rejected': step.done == false,
      }"
      v-for="(step, i) in steps"
      :key="step.name"
    >
      <h3 class="c-stepper__title">{{ String(i + 1).padStart(2, "0") }}</h3>
      <p class="c-stepper__desc">{{ step.name }}</p>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    steps: {
      // steps Array<{ name: String, done: Boolean }>
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
:root {
  --circle-size: clamp(1.5rem, 2rem, 3rem);
  --spacing: clamp(0.25rem, 0.3rem, 0.5rem);
}

.c-stepper {
  display: flex;
  padding: 0;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    // opacity: 0.5;
    margin: 0 auto 1rem;
  }

  &--approved {
    &:before {
      background-color: #0090e7;
    }

    &:after {
      background-color: #e0e0e0 !important;
    }
  }

  &--rejected {
    &:before {
      background-color: #fc424a;
    }

    &:after {
      background-color: #e0e0e0 !important;
    }
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: rgba(255, 255, 255, 0.4);
      order: -1;
    }
  }
}

.c-stepper__title {
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}
</style>