import { amgApi } from "@/service/axios";

class OfferService {
  async getCurrentCreditorByDsListCreditId(params) {
    try {
      const data = await amgApi.post(
        `debt-solution/get-current-creditor-by-ds-list-credit-id`,
        params
      );
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on getCurrentCreditorByDsListCreditId:",
        error
      );
      throw error;
    }
  }
}

export default new OfferService();
