<template>
  <b-modal
    ref="info-essential"
    title="Essential Information"
    title-tag="h3"
    modal-class="modal-primary"
    size="xmd"
    no-close-on-backdrop
    @hidden="close"
    scrollable
  >
    <!-- status employees -->
    <b-row class="mt-1">
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
        >
          <template #header>
            <div style="padding-top: 12px; padding-left: 20px">
              <p class="font-weight-bolder">Employment Status</p>
            </div>
          </template>
          <b-row>
            <b-col>
              <b-row>
                <b-col md="3">
                  <label for>Civil Status</label>
                  <b-form-input
                    v-model="applicantObject.civilStatus"
                    :disabled="true"
                  />
                </b-col>
                <b-col md="3">
                  <label for>Applicant's Employee</label>
                  <b-form-input
                    v-model="applicantObject.applicantEmployer"
                    :disabled="true"
                  />
                </b-col>
                <b-col
                  md="3"
                  v-if="applicantObject.applicantEmployer == 'EMPLOYED'"
                >
                  <label>Remunerated</label>
                  <b-form-input
                    v-model="applicantObject.remunerated"
                    :disabled="true"
                  />
                </b-col>
                <b-col md="3">
                  <label for>Dependents</label>
                  <b-form-input
                    v-model="applicantObject.dependents"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col v-if="applicantObject.remunerated == 'MONTHLY'" md="3">
                  <label for> Payment Date</label>
                  <b-form-datepicker
                    v-model="applicantObject.payment_date"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="en-US"
                    :format="'MM/dd/yyyy'"
                    :disabled="true"
                  />
                </b-col>
                <b-col md="3">
                  <label>Type Payment</label>
                  <b-form-input
                    v-model="applicantObject.type_payment"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1" v-if="coApplicantObject.civilStatus">
                <b-col md="3">
                  <label v-if="applicant == true" for
                    >Co-applicant Civil Status</label
                  >
                  <b-form-input
                    v-if="applicant == true"
                    v-model="coApplicantObject.civilStatus"
                    :disabled="true"
                  />
                </b-col>
                <b-col md="3">
                  <label v-if="applicant == true" for
                    >Co-applicant employer</label
                  >
                  <b-form-input
                    v-if="applicant == true"
                    v-model="coApplicantObject.coApplicantEmployer"
                    :disabled="true"
                  />
                </b-col>
                <b-col md="3">
                  <label v-if="applicant == true" for
                    >Co-applicant Dependents</label
                  >
                  <b-form-input
                    v-if="applicant == true"
                    v-model="coApplicantObject.dependents"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- incomes -->
    <b-row>
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
          style="height: 90%"
        >
          <template #header>
            <div
              style="
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 20px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <p class="font-weight-bolder" style="margin: auto">Incomes</p>
              </div>
              <div>
                <b-button
                  v-b-toggle.collapse-1
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  @click="changeTextIncomes()"
                  >{{ textIncomesButton }}
                  <feather-icon :icon="iconIncomesButtom" size="18" />
                </b-button>
              </div>
            </div>
          </template>
          <b-row style="margin-left: 0rem !important">
            <b-collapse id="collapse-1" style="width: 100%">
              <b-row style="margin-right: 0 !important">
                <b-col md="4">
                  <label>Applicant's monthly net income</label>
                  <money
                    id="app-monthly-net-income"
                    v-model="applicantObject.monthlyNetIncome"
                    v-bind="vMoney"
                    :disabled="true"
                    class="form-control"
                  />
                </b-col>

                <b-col md="4">
                  <label>Additional monthly net income</label>
                  <money
                    id="additiona-m-net-income"
                    v-model="applicantObject.additionalMonthlyNetincome"
                    v-bind="vMoney"
                    :disabled="true"
                    class="form-control"
                  />
                </b-col>
                <b-col md="4">
                  <label>Co-applicant's monthly net income</label>
                  <money
                    id="co-applicant-net"
                    v-model="coApplicantObject.monthlyNetIncome"
                    v-bind="vMoney"
                    :disabled="true || !(applicant == true)"
                    class="form-control"
                  />
                </b-col>
              </b-row>
            </b-collapse>
          </b-row>
          <b-row>
            <b-col class="mt-2" md="12">
              <b-input-group prepend="Monthly Net Income">
                <div
                  class="form-control d-flex align-items-center justify-content-center bg-transparent border"
                >
                  $ {{ totalMonthlyNetIncome | currencyZero }}
                </div>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- expenses -->
    <b-row>
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
        >
          <template #header>
            <div
              style="
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 20px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
              "
            >
              <div>
                <p class="font-weight-bolder" style="margin: auto">Expenses</p>
              </div>
              <div>
                <b-button
                  v-b-toggle.collapse-2
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  @click="changeTextCostLiving()"
                  >{{ textCostLivingButton }}
                  <feather-icon :icon="iconCostLivingButtom" size="18" />
                </b-button>
              </div>
            </div>
          </template>
          <b-row>
            <b-col>
              <b-card
                header-text-variant="white"
                header-bg-variant="info"
                header-class="p-0"
                body-class="card-body-border"
                style="margin-bottom: 0rem !important"
              >
                <template #header>
                  <div
                    style="
                      padding-top: 12px;
                      padding-left: 20px;
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      background: #5e73c7;
                    "
                  >
                    <div>
                      <p class="font-weight-bolder">Cost of living</p>
                    </div>
                  </div>
                </template>
                <b-collapse id="collapse-2" style="width: 100%">
                  <b-row>
                    <b-col md="8">
                      <v-select
                        v-model="selected"
                        :options="options"
                        label="text"
                        multiple
                        placeholder="Utilities"
                        :disabled="true"
                        :close-on-select="false"
                        :hidden="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-for="(select, index) in selected"
                      :key="select.id"
                      sm="6"
                      md="6"
                      xl="2"
                      class="mt-1"
                    >
                      <div>
                        <label>{{ select.text }}</label>
                        <money
                          :id="`inp-${index}`"
                          v-model="select.value"
                          v-bind="vMoney"
                          :disabled="true"
                          class="form-control"
                        />
                      </div>
                    </b-col>
                    <b-col class="mt-1" sm="6" md="6" xl="2">
                      <label>Total Utilities</label>
                      <div
                        class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                      >
                        $ {{ totalDato5 | currencyZero }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="6">
                      <label> Food(expenses in store or eating outside) </label>
                      <money
                        id="dato7"
                        v-model="dato7"
                        v-bind="vMoney"
                        :disabled="true"
                        class="form-control"
                      />
                    </b-col>
                    <b-col md="6">
                      <label for>Insurance</label>
                      <money
                        id="dato8"
                        v-model="dato8"
                        v-bind="vMoney"
                        :disabled="true"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-for="(car, index) in cars"
                      :key="car.id"
                      class="mt-1"
                      sm="6"
                      md="6"
                      xl="2"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <label>{{ car.text }}</label>
                        <label v-if="index != 0 && index == cars.length - 1">
                          <feather-icon
                            v-if="!true"
                            icon="XCircleIcon"
                            class="cursor-pointer text-danger font-medium-2"
                            @click="cars.splice(index, 1)"
                          />
                        </label>
                      </div>
                      <money
                        :id="`car-${index}`"
                        v-model="car.value"
                        v-bind="vMoney"
                        :disabled="true"
                        class="form-control"
                      />
                    </b-col>
                    <b-col class="mt-1" sm="6" md="6" xl="2">
                      <label>Gasoline</label>
                      <money
                        id="gasoline"
                        v-model="gasoline"
                        v-bind="vMoney"
                        :disabled="true"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <v-select
                        v-model="selectedOther"
                        :options="optionsOthers"
                        label="text"
                        multiple
                        :close-on-select="false"
                        placeholder="Others"
                        :hidden="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-for="(select, index) in selectedOther"
                      :key="select.id"
                      sm="6"
                      md="6"
                      xl="2"
                      class="mt-1"
                    >
                      <div>
                        <label>
                          <span v-if="select.id != 11">{{ select.text }}</span>
                          <span v-else>
                            <b-form-input
                              v-model="select.text"
                              type="text"
                              placeholder="Specify..."
                              :disabled="true"
                              size="sm"
                              class="py-0"
                            />
                          </span>
                        </label>
                        <money
                          :id="`inp-${index}`"
                          v-model="select.value"
                          v-bind="vMoney"
                          :disabled="true"
                          class="form-control"
                        />
                      </div>
                    </b-col>
                    <b-col class="mt-1" sm="6" md="6" xl="2">
                      <label>Total Others</label>
                      <div
                        class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                      >
                        $ {{ totalOthers.toFixed(2) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row>
                  <b-col class="mt-2" md="12">
                    <b-input-group prepend="Total monthly living cost">
                      <div
                        class="form-control d-flex align-items-center justify-content-center bg-transparent border"
                      >
                        $ {{ totalDato7 | currencyZero }}
                      </div>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-card
                header="Housing expenses"
                header-text-variant="white"
                header-bg-variant="info"
                header-class="p-0"
                body-class="pt-1 card-body-border"
                style="margin-bottom: 0rem !important"
              >
                <template #header>
                  <div
                    style="
                      padding-top: 12px;
                      padding-left: 20px;
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      background: #5e73c7;
                    "
                  >
                    <div>
                      <p class="font-weight-bolder">Housing Expenses</p>
                    </div>
                    <div></div>
                  </div>
                </template>
                <b-collapse id="collapse-2">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-start"
                    >
                      <span>House Owner</span>
                      <div
                        class="d-flex ml-1 align-items-center justify-content-center"
                      >
                        <b-form-checkbox
                          id="housing"
                          v-model="housingExpensesObject.housing"
                          name="housing"
                          :disabled="true"
                          switch
                        />
                        <span v-if="housingExpensesObject.housing">YES</span>
                        <span v-else>NO</span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <label>Monthly payment or monthly rent</label>
                      <money
                        id="month-payment-rent"
                        v-model="
                          housingExpensesObject.monthlyPaymentHousingExpenses
                        "
                        v-bind="vMoney"
                        :disabled="true"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row>
                  <b-col class="mt-2" md="12">
                    <b-input-group prepend="Total monthly housing expenses">
                      <div
                        class="form-control d-flex align-items-center justify-content-center bg-transparent border"
                      >
                        $
                        {{
                          housingExpensesObject.monthlyPaymentHousingExpenses
                            | currencyZero
                        }}
                      </div>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- goals -->
    <b-row>
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
        >
          <template #header>
            <div style="padding-top: 12px; padding-left: 20px">
              <p class="font-weight-bolder">Goals</p>
            </div>
          </template>
          <b-row>
            <b-col
              v-for="(goal, index) in arrayGoals"
              :key="goal.id"
              md="6"
              lg="3"
              class="mt-1"
            >
              <div class="d-flex justify-content-between align-items-center">
                <label :for="`date-label-${index}`">{{ goal.text }}</label>
                <label v-if="index != 0 && index == arrayGoals.length - 1">
                  <feather-icon
                    v-if="!true"
                    icon="XCircleIcon"
                    class="font-medium-3 text-danger cursor-pointer"
                    @click="arrayGoals.splice(index, 1)"
                  />
                </label>
              </div>

              <b-form-input
                :id="`date-label-${index}`"
                v-model="goal.goal"
                :disabled="true"
              />
              <label class="mt-1" :for="`date-goal-${index}`">Date</label><br />
              <kendo-datepicker
                style="width: 52%"
                :format="'MM/dd/yyyy'"
                v-mask="'##/##/####'"
                :id="`date-goal-${index}`"
                v-model="goal.date"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :disabled="true"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Pueden dar mas dinero -->
    <b-row>
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
        >
          <template #header>
            <div style="padding-top: 12px; padding-left: 20px">
              <p class="font-weight-bolder">Additional Information</p>
            </div>
          </template>
          <b-row>
            <b-col md="12" class="mt-1">
              <b-row>
                <b-col cols="12">
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <span>Availability to give full amounts?</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <div
                      class="d-flex ml-1 align-items-center justify-content-center"
                    >
                      <b-form-checkbox
                        id="fullAmounts"
                        v-model="fullAmounts"
                        name="fullAmounts"
                        :disabled="true"
                        switch
                      />
                      <span v-if="fullAmounts != ''">YES</span>
                      <span v-else>NO</span>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- template footer -->
    <template v-slot:modal-footer="{ ok }">
      <b-button @click="ok" variant="primary">Close</b-button>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import VSelectOptions from "@/views/crm/views/clients/components/data/options.modal-questionnaire";
export default {
  components: {
    vSelect,
  },
  props: {
    account: null,
    event: null,
    id_analysis: null,
  },
  data() {
    return {
      creditors: [],
      id_analisis: "",
      selected: [], // Must be an array reference!
      selectedOther: [],
      fullAmounts: false,
      options: [
        { id: 1, text: "Electricity", value: 0 },
        { id: 2, text: "Gas", value: 0 },
        { id: 3, text: "Water", value: 0 },
        { id: 4, text: "Telephone", value: 0 },
        { id: 5, text: "Cable", value: 0 },
        { id: 6, text: "Internet", value: 0 },
        { id: 7, text: "Trash", value: 0 },
      ],
      optionsOthers: [
        { id: 1, text: "Child Support", value: 0 },
        { id: 2, text: "Spouse Suppport", value: 0 },
        { id: 3, text: "Clothes", value: 0 },
        { id: 4, text: "Education", value: 0 },
        { id: 5, text: "Health & SelfCare", value: 0 },
        { id: 6, text: "Transportation", value: 0 },
        { id: 7, text: "Laundry", value: 0 },
        { id: 8, text: "Donations", value: 0 },
        { id: 9, text: "Family Support", value: 0 },
        { id: 10, text: "Recreation", value: 0 },
        { id: 11, text: "Others", value: 0 },
      ],
      applicantObject: {
        firstName: "",
        middleName: "",
        lastName: "",
        status: "",
        dob: "",
        ssn: "",
        phoneM: "",
        phoneH: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        civilStatus: "",
        applicantEmployer: "",
        dependents: "",
        monthlyNetIncome: 0,
        additionalMonthlyNetincome: 0,
        // new columns
        remunerated: 0,
        payment_date: "",
        type_payment: 0,
      },
      coApplicantObject: {
        firstName: "",
        middleName: "",
        lastName: "",
        status: "",
        dob: "",
        ssn: "",
        phoneM: "",
        civilStatus: "",
        coApplicantEmployer: "",
        dependents: "",
        monthlyNetIncome: 0,
      },
      housingExpensesObject: {
        housing: "",
        monthlyPaymentHousingExpenses: 0,
      },
      dato6: "",
      dato7: "",
      dato8: "",
      workphone1: "",
      cars: [{ text: "Car 1", value: 0 }],
      arrayGoals: [{ text: "1st Goal", goal: null, date: null }],
      gasoline: 0,
      applicant: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      textIncomesButton: "See More",
      iconIncomesButtom: "ChevronsDownIcon",
      textCostLivingButton: "See More",
      iconCostLivingButtom: "ChevronsDownIcon",
      textHousingExpensesButton: "See More",
      iconHousingExpensesButtom: "ChevronsDownIcon",
      optionsVSelect: VSelectOptions,
    };
  },
  methods: {
    changeTextIncomes() {
      if (this.textIncomesButton == "See More") {
        this.textIncomesButton = "See Less";
        this.iconIncomesButtom = "ChevronsUpIcon";
      } else {
        this.textIncomesButton = "See More";
        this.iconIncomesButtom = "ChevronsDownIcon";
      }
    },
    changeTextCostLiving() {
      if (this.textCostLivingButton == "See More") {
        this.textCostLivingButton = "See Less";
        this.iconCostLivingButtom = "ChevronsUpIcon";
      } else {
        this.textCostLivingButton = "See More";
        this.iconCostLivingButtom = "ChevronsDownIcon";
      }
    },
    changeTextHousingExpenses() {
      if (this.textHousingExpensesButton == "See More") {
        this.textHousingExpensesButton = "See Less";
        this.iconHousingExpensesButtom = "ChevronsUpIcon";
      } else {
        this.textHousingExpensesButton = "See More";
        this.iconHousingExpensesButtom = "ChevronsDownIcon";
      }
    },
    close() {
      this.$emit("close");
    },
    async allDebtSolution() {
      this.addPreloader();
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-credits-debt-solution",
          {
            event: this.event,
            account: this.account,
          }
        );
        if (response.status === 200) {
          this.creditors = response.data;
          if (this.creditors.length > 0) {
            this.id_analisis = this.creditors[0].id_analisis;
          } else {
            this.id_analisis = "";
          }
          await this.analisisDebsolution();
          this.removePreloader();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async analisisDebsolution() {
      this.addPreloader();
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-analysis-debt-solution",
          {
            event: this.event,
            account: this.account,
            id: this.id_analisis,
          }
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            response.data[0].full_amounts == 1
              ? (this.fullAmounts = true)
              : (this.fullAmounts = false);
            this.applicantObject.firstName = response.data[0].name;
            this.applicantObject.lastName = response.data[0].last;
            this.applicantObject.dob = response.data[0].dob;
            this.applicantObject.ssn = response.data[0].ssn;
            this.coApplicantObject.firstName = response.data[0].c_name;
            this.coApplicantObject.lastName = response.data[0].c_last;
            this.coApplicantObject.dependents = response.data[0].c_dependents;
            this.coApplicantObject.dob = response.data[0].c_dob;
            this.coApplicantObject.ssn = response.data[0].c_ssn;
            this.coApplicantObject.civilStatus =
              response.data[0].c_status_civil;
            this.applicantObject.address = response.data[0].address;
            this.applicantObject.city = response.data[0].city;
            this.applicantObject.state = response.data[0].state;
            this.applicantObject.zipcode = response.data[0].zipcode;
            this.applicantObject.civilStatus = response.data[0].status_civil;
            this.applicantObject.dependents = response.data[0].dependents;
            this.applicantObject.phoneM = response.data[0].phone;
            this.applicantObject.applicantEmployer = response.data[0].employer;
            this.applicantObject.phoneH = response.data[0].phone_work;
            this.coApplicantObject.coApplicantEmployer =
              response.data[0].c_employer;
            this.coApplicantObject.phoneM = response.data[0].c_phone_work;
            this.applicantObject.monthlyNetIncome = Number(
              response.data[0].applicant_monthly
            );
            this.coApplicantObject.monthlyNetIncome = Number(
              response.data[0].c_applicant_monthly
            );
            this.applicantObject.additionalMonthlyNetincome = Number(
              response.data[0].adicional_monthly
            );
            this.housingExpensesObject.monthlyPaymentHousingExpenses = Number(
              response.data[0].monthly_payment
            );
            this.dato6 = Number(response.data[0].telephone);
            this.dato7 = Number(response.data[0].food);
            this.dato8 = Number(response.data[0].insurance);
            this.workphone1 = response.data[0].phone_work;
            if (response.data[0].car_payment) {
              this.cars[0].value = Number(response.data[0].car_payment);
            }
            for (let i = 2; i <= 5; i++) {
              if (response.data[0][`car_payment_${i}`]) {
                const car = {
                  text: `Car ${i}`,
                  value: Number(response.data[0][`car_payment_${i}`]),
                };
                this.cars.push(car);
              }
            }
            this.gasoline = Number(response.data[0].gasoline);

            for (let i = 1; i <= 4; i++) {
              if (i == 1) {
                this.arrayGoals[0].goal = response.data[0].goal1;
                this.arrayGoals[0].date = response.data[0].date_g1;
              } else if (response.data[0][`goal${i}`]) {
                const goal = {
                  text: `${this.ordinalSuffixOf(i)} Goal`,
                  goal: response.data[0][`goal${i}`],
                  date: response.data[0][`date_g${i}`],
                };
                this.arrayGoals.push(goal);
              }
            }

            this.others = response.data[0].others_t;
            this.applicantObject.middleName = response.data[0].middle_name;
            this.coApplicantObject.middleName = response.data[0].c_middle;
            this.applicantObject.status = response.data[0].state_lead;
            this.coApplicantObject.status = response.data[0].c_status_lead;
            this.applicantObject.phoneH = response.data[0].phone2;
            this.applicantObject.email = response.data[0].email;
            // new gets
            this.applicantObject.remunerated = response.data[0].remunerated;
            this.applicantObject.payment_date = response.data[0].payment_date;
            this.applicantObject.type_payment = response.data[0].type_payment;
            const utilitiesObjects =
              response.data[0].json_montutility == null
                ? []
                : JSON.parse(response.data[0].json_montutility);
            const othersObject =
              response.data[0].json_montothers == null
                ? []
                : JSON.parse(response.data[0].json_montothers);

            utilitiesObjects.map((utility) => {
              this.options.map((option) => {
                if (Number(utility.id) == option.id) {
                  option.value = Number(utility.mont);
                  this.selected.push(option);
                }
              });
            });
            othersObject.map((other) => {
              this.optionsOthers.map((option) => {
                if (Number(other.id) == option.id) {
                  option.value = Number(other.mont);
                  this.selectedOther.push(option);
                }
              });
            });
            this.applicant = !!(
              this.coApplicantObject.firstName != null ||
              this.coApplicantObject.dob != null ||
              this.coApplicantObject.ssn != null ||
              this.coApplicantObject.coApplicantEmployer != null ||
              this.coApplicantObject.phoneM != null ||
              this.coApplicantObject.monthlyNetIncome != null
            );
            if (response.data[0].housing == null) {
              this.housingExpensesObject.housing = false;
            } else if (response.data[0].housing == 1) {
              this.housingExpensesObject.housing = true;
            } else if (response.data[0].housing == 0) {
              this.housingExpensesObject.housing = false;
            }
            this.removePreloader();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    ordinalSuffixOf(i) {
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return `${i}st`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd`;
      }
      return `${i}th`;
    },
  },
  mounted() {
    this.toggleModal("info-essential");
    if (this.id_analysis == null) {
      this.allDebtSolution();
    } else {
      this.id_analisis = this.id_analysis;
      this.analisisDebsolution();
    }
  },
  computed: {
    totalMonthlyNetIncome() {
      return Number(
        this.applicantObject.monthlyNetIncome +
          this.applicantObject.additionalMonthlyNetincome +
          this.coApplicantObject.monthlyNetIncome
      );
    },
    totalDato5() {
      const total = this.selected.reduce(
        (sum, select) => sum + Number(select.value),
        0
      );
      return total;
    },
    totalDato7() {
      const total = this.cars.reduce((sum, car) => sum + Number(car.value), 0);
      const totalOthers = this.selectedOther.reduce(
        (sum, other) => sum + Number(other.value),
        0
      );
      return Number(
        total +
          totalOthers +
          this.gasoline +
          this.totalDato5 +
          this.dato7 +
          this.dato8
      );
    },
    totalOthers() {
      const totalOthers = this.selectedOther.reduce(
        (sum, other) => sum + Number(other.value),
        0
      );
      return totalOthers;
    },
  },
};
</script>
<style scoped>
</style>
