<template>
  <div>
    <b-input-group>
      <b-input-group-prepend
        is-text
        v-b-tooltip.hover
        title="Tracking Balance"
        class="cursor-pointer"
        @click="tracking"
      >
        <feather-icon icon="AlignJustifyIcon" size="18" />
      </b-input-group-prepend>
      <money
        required
        v-bind="vMoney"
        v-model="bankAccount.balance"
        class="form-control"
        :disabled="!bankAccount.change_balance"
        :class="newBalance < 0 ? 'border-danger' : ''"
      ></money>
      <template #append>
        <b-button-group>
          <b-button
            class="p-0 pl-1 pr-1"
            v-if="!bankAccount.change_balance"
            @click="changeBalance('DEPOSIT')"
            variant="outline-warning"
            v-b-tooltip.hover
            title="Deposit"
          >
            <feather-icon icon="PlusIcon" size="18" class="cursor-pointer" />
          </b-button>
          <b-button
            class="p-0 pl-1 pr-1"
            v-if="!bankAccount.change_balance"
            @click="changeBalance('WITHDRAWAL')"
            variant="outline-warning"
            v-b-tooltip.hover
            title="Withdrawal"
          >
            <feather-icon icon="MinusIcon" size="18" class="cursor-pointer" />
          </b-button>
          <b-button
            class="p-0 pl-1 pr-1"
            v-if="bankAccount.change_balance"
            variant="outline-danger"
            @click="
              [
                (bankAccount.balance = bankAccount.old_balance),
                (bankAccount.change_balance = false),
                (type = ''),
              ]
            "
          >
            <feather-icon icon="XIcon" size="18" />
          </b-button>
          <b-button
            class="p-0 pl-1 pr-1"
            v-if="bankAccount.change_balance"
            :disabled="!comparingAmounts"
            @click="saveBalance(item)"
            :variant="
              !comparingAmounts
                ? 'secondary cursor-not-allowed'
                : 'outline-success'
            "
          >
            <feather-icon icon="SaveIcon" size="18" />
          </b-button>
        </b-button-group>
      </template>
    </b-input-group>
    <div v-bind="divContainer">
      <template v-if="bankAccount.change_balance && type">
        <span class="mr-1" v-b-tooltip.hover title="Current Balance">
          <feather-icon icon="ShieldIcon" v-bind="marginRight" size="18" />
          <span>{{ bankAccount.old_balance | formatMoney }}</span>
        </span>
        <span
          class="ml-1"
          v-if="comparingAmounts"
          v-b-tooltip.hover
          title="New Balance"
        >
          <feather-icon
            size="18"
            :icon="isIncrease ? 'TrendingUpIcon' : 'TrendingDownIcon'"
            :class="isIncrease ? 'text-success' : 'text-danger'"
            v-bind="marginRight"
          />
          <span>{{ newBalance | formatMoney }}</span>
        </span>
      </template>
    </div>
    <tracking-e-check-balance
      v-if="TrackingECheckBalance.length > 0"
      :trackingBalance="TrackingECheckBalance"
      :bankAccount="bankAccount"
      @close="TrackingECheckBalance = []"
    />
  </div>
</template>
<script>
import VMoney from "v-money";
import CheckServices from "@/views/commons/components/check-expenses/services/checks.services.js";
import { mapGetters } from "vuex";
import TrackingECheckBalance from "@/views/commons/components/check-expenses/modals/TrackingECheckBalance.vue";
export default {
  name: "ECheckBalance",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    VMoney,
    TrackingECheckBalance,
  },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      bankAccount: null,
      type: "",
      TrackingECheckBalance: [],
    };
  },
  watch: {
    item: {
      handler(value) {
        this.bankAccount = {
          ...value,
          balance: Number(value.balance),
          old_balance: Number(value.balance),
          change_balance: false,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isIncrease() {
      return this.type === "DEPOSIT";
    },
    newBalance() {
      return this.isIncrease
        ? this.bankAccount.balance + this.bankAccount.old_balance
        : this.bankAccount.old_balance - this.bankAccount.balance;
    },
    comparingAmounts() {
      return this.bankAccount.balance > 0 && this.validBalance
        ? this.reactiveBalance
        : false;
    },
    validBalance() {
      return this.isIncrease ? this.newBalance > 0 : this.newBalance >= 0;
    },
    reactiveBalance() {
      let balance = this.bankAccount.balance;
      let old_balance = this.bankAccount.old_balance;
      return this.newBalance >= 0 || balance != old_balance;
    },
    marginRight() {
      return {
        style: {
          marginRight: ".1rem",
        },
      };
    },
    divContainer() {
      return {
        style: {
          height: "19.55px",
          padding: "3px 0px",
        },
      };
    },
  },
  methods: {
    changeBalance(type) {
      this.bankAccount.change_balance = true;
      this.bankAccount.balance = 0;
      this.type = type;
    },
    async saveBalance() {
      try {
        if (!this.comparingAmounts) return;
        this.addPreloader();
        const { balance, old_balance, bank_check_account_id } =
          this.bankAccount;
        const params = {
          balance: Number(balance),
          old_balance: Number(old_balance),
          created_by: this.currentUser.user_id,
          id: bank_check_account_id,
          type: this.type.toUpperCase(),
          new_balance: Number(this.newBalance),
        };
        const { message, id } = await CheckServices.updateBalanceEcheck(params);
        this.$emit("updateBalance", id);
        this.showToast(
          this.type === "DEPOSIT" ? "success" : "warning",
          "top-right",
          this.type,
          this.type === "DEPOSIT" ? "TrendingUpIcon" : "TrendingDownIcon",
          message
        );
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    async tracking() {
      try {
        this.addPreloader();
        const data = await CheckServices.getTrackingBalance({
          id: this.bankAccount.bank_check_account_id,
        });
        this.removePreloader();
        this.TrackingECheckBalance = data;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
