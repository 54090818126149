import { amgApi } from "@/service/axios";

class ChecksService {
  async getCheckAccounts(params) {
    try {
      return await amgApi.post(
        "ceo/dashboard/expenses/settings/checks/index",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getBanks() {
    try {
      return await amgApi.get(
        "ceo/dashboard/expenses/settings/checks/banks/index"
      );
    } catch (error) {
      throw error;
    }
  }

  async saveBankAccount(params) {
    try {
      return await amgApi.post(
        "ceo/dashboard/expenses/settings/checks/banks/save-account",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async updateBankAccount(params) {
    try {
      return await amgApi.post(
        "ceo/dashboard/expenses/settings/checks/banks/update-account",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getModules() {
    try {
      return await amgApi.get("new-commons/all-modules/index");
    } catch (error) {
      throw error;
    }
  }

  async deleteCheckAccount(params) {
    try {
      return await amgApi.post(
        `ceo/dashboard/expenses/settings/checks/banks/delete-account`,
        params
      );
    } catch (error) {
      throw error;
    }
  }
  async updateBalanceEcheck(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/e-checks/update-balance",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getECheckBankAccounts() {
    try {
      const { data } = await amgApi.get(
        "ceo/dashboard/expenses/settings/e-checks/get-e-check-bank-accounts"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFreezeTracking(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/e-checks/get-freeze-tracking",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTrackingBalance(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/e-checks/tracking-balance",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async listPermissionECheckModules() {
    try {
      const { data } = await amgApi.get(
        "ceo/dashboard/expenses/settings/e-checks/list-permission-modules"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async savePermissionECheckModules(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/e-checks/save-permission-modules",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async changeStatusBankAccount(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/checks/banks/change-status",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTotalPaid(params) {
    try {
      const { data } = await amgApi.post(
        "ceo/dashboard/expenses/settings/e-checks/get-total-paid",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ChecksService();
